import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { Auth, Cache } from 'aws-amplify'
import store from '../store'
import { setUserData } from '@/assets/js/common';
import { AUTH_EXPIRATION_DAYS } from '@/assets/js/const';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '東北システム' }
  },
  // ログイン
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { title: 'ログイン' }
  },
  // 発注検索
  {
    path: '/order_search',
    name: 'OrderSearch',
    component: () => import('../views/OrderSearch.vue'),
    meta: { title: '発注検索' },
    props: true
  },
  // 発注入力
  {
    path: '/order_input',
    name: 'OrderInput',
    component: () => import('../views/OrderInput.vue'),
    meta: { title: '発注入力' },
    props: true
  },
  // CSV出力（仕入）
  {
    path: '/csv_print',
    name: 'CsvPrint',
    component: () => import('../views/CsvPrint.vue'),
    meta: { title: 'CSV出力（仕入）' }
  },
  // 仮伝印刷
  {
    path: '/temp_print',
    name: 'TempPrint',
    component: () => import('../views/TempPrint.vue'),
    meta: { title: '仮伝印刷' }
  },
  // 商品検索
  {
    path: '/product_search',
    name: 'ProductSearch',
    component: () => import('../views/ProductSearch.vue'),
    meta: { title: '商品検索' },
    props: true
  },
  // 商品登録
  {
    path: '/product_insert',
    name: 'ProductInsert',
    component: () => import('../views/ProductInsert.vue'),
    meta: { title: '商品登録' },
    props: true
  },
  // 仕入先マスタ保守 検索
  {
    path: '/supplier_search',
    name: 'SupplierSearch',
    component: () => import('../views/SupplierSearch.vue'),
    meta: { title: ' 仕入先検索' },
    props: true
  },
  // 仕入先マスタ登録
  {
    path: '/supplier_insert',
    name: 'SupplierInsert',
    component: () => import('../views/SupplierInsert.vue'),
    meta: { title: '仕入先登録' },
    props: true
  },
  // 得意先マスタ保守 検索
  {
    path: '/client_search',
    name: 'ClientSearch',
    component: () => import('../views/ClientSearch.vue'),
    meta: { title: '得意先検索' },
    props: true
  },
  // 得意先マスタ登録
  {
    path: '/client_insert',
    name: 'ClientInsert',
    component: () => import('../views/ClientInsert.vue'),
    meta: { title: '得意先登録' },
    props: true
  },
  // 担当者マスタ保守 検索
  {
    path: '/manager_search',
    name: 'ManagerSearch',
    component: () => import('../views/ManagerSearch.vue'),
    meta: { title: '担当者検索' },
    props: true
  },
  // 担当者マスタ登録
  {
    path: '/manager_insert',
    name: 'ManagerInsert',
    component: () => import('../views/ManagerInsert.vue'),
    meta: { title: '担当者登録' },
    props: true
  },
  // 初期設定
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { title: '初期設定' }
  },
  // 見積検索
  {
    path: '/estimate_search',
    name: 'EstimateSearch',
    component: () => import('../views/EstimateSearch.vue'),
    meta: { title: '見積検索' },
    props: true
  },
  // 見積入力
  {
    path: '/estimate_input',
    name: 'EstimateInput',
    component: () => import('../views/EstimateInput.vue'),
    meta: { title: '見積入力' },
    props: true
  },
  // 東北商品センター発注検索
  {
    path: '/center_order_search',
    name: 'CenterOrderSearch',
    component: () => import('../views/CenterOrderSearch.vue'),
    meta: { title: '東北商品センター発注検索' },
    props: true
  },
  // 東北商品センター発注入力
  {
    path: '/center_order_input',
    name: 'CenterOrderInput',
    component: () => import('../views/CenterOrderInput.vue'),
    meta: { title: '東北商品センター発注入力' },
    props: true
  },
  // 商品原価更新依頼
  {
    path: '/product_price_update_request',
    name: 'ProductPriceUpdateRequest',
    component: () => import('../views/ProductPriceUpdateRequest.vue'),
    meta: { title: '商品原価更新依頼' }
  },
  // 東北商品センターCSV出力（仕入）
  {
    path: '/center_csv_print',
    name: 'CenterCsvPrint',
    component: () => import('../views/CenterCsvPrint.vue'),
    meta: { title: '東北商品センターCSV出力（仕入）' }
  },
  // ユーザー切替（管理者ユーザー向け）
  {
    path: '/change_auth_user',
    name: 'ChangeAuthUser',
    component: () => import('../views/ChangeAuthUser.vue'),
    meta: { title: 'ユーザー切替' }
  },

  /**
   * 帳票
   */
  // 発注書（発注簿用）（帳票）
  {
    path: '/order/:hacchu_data_id',
    name: 'Order',
    component: () => import('../components/chouhyou/order_record/Order.vue'),
    meta: {
      title: '発注書（発注簿用）',
      desc: '販売管理システムの発注書（発注簿用）PDF出力です。',
    },
    props:true
  },
  // 修理検査依頼書（帳票）
  {
    path: '/repairInspectRequest/:hacchu_data_id',
    name: 'RepairInspectRequest',
    component: () => import('../components/chouhyou/order_record/RepairInspectRequest.vue'),
    meta: {
      title: '修理検査依頼書',
      desc: '販売管理システムの修理検査依頼書PDF出力です。',
    },
    props:true
  },
  // トシン受発注票（帳票）
  {
    path: '/toshinOrder/:params',
    name: 'ToshinOrder',
    component: () => import('../components/chouhyou/order_record/ToshinOrder.vue'),
    meta: {
      title: 'トシン受発注票',
      desc: '販売管理システムのトシン受発注票PDF出力です。',
    },
    props:true
  },
  // 返品依頼書（帳票）
  {
    path: '/returnRequest/:params',
    name: 'ReturnRequest',
    component: () => import('../components/chouhyou/common/ReturnRequest.vue'),
    meta: {
      title: '返品依頼書',
      desc: '販売管理システムの返品依頼書PDF出力です。',
    },
    props:true
  },
  // 返品伝票（帳票）
  {
    path: '/returnSlip/:params',
    name: 'ReturnSlip',
    component: () => import('../components/chouhyou/common/ReturnSlip.vue'),
    meta: {
      title: '返品伝票',
      desc: '販売管理システムの返品伝票PDF出力です。',
    },
    props:true
  },
  // 納期一覧表（帳票）
  {
    path: '/deliveryDateNotification/:params',
    name: 'DeliveryDateNotification',
    component: () => import('../components/chouhyou/order_record/DeliveryDateNotification.vue'),
    meta: {
      title: '納期一覧表',
      desc: '販売管理システムの納期一覧表PDF出力です。',
    },
    props:true
  },
  // 仮伝納品書（帳票）
  {
    path: '/tempDeliverySlip/:params',
    name: 'TempDeliverySlip',
    component: () => import('../components/chouhyou/order_record/TempDeliverySlip.vue'),
    meta: {
      title: '仮伝納品書',
      desc: '販売管理システムの仮伝納品書PDF出力です。',
    },
    props:true
  },
  // トシン伝票明細（帳票）
  {
    path: '/toshinSalesSlipDetails/:params',
    name: 'ToshinSalesSlipDetails',
    component: () => import('../components/chouhyou/order_record/ToshinSalesSlipDetails.vue'),
    meta: {
      title: 'トシン伝票明細',
      desc: '販売管理システムのトシン伝票明細PDF出力です。',
    },
    props:true
  },
  // 見積書（帳票）
  {
    path: '/estimate/:mitsumoriDataId',
    name: 'Estimate',
    component: () => import('../components/chouhyou/estimate/Estimate.vue'),
    meta: {
      title: '見積書',
      desc: '販売管理システムの見積書PDF出力です。',
    },
    props:true
  },
  // 発注書（東北商品センター用）（帳票）
  {
    path: '/centerOrder/:hacchu_data_id',
    name: 'CenterOrder',
    component: () => import('../components/chouhyou/tohoku_center/CenterOrder.vue'),
    meta: {
      title: '発注書（東北商品センター用）',
      desc: '販売管理システムの発注書（東北商品センター用）PDF出力です。',
    },
    props:true
  },
  // トシン伝票明細（東北商品センター用）（帳票）
  {
    path: '/centerToshinSalesSlipDetails/:params',
    name: 'CenterToshinSalesSlipDetails',
    component: () => import('../components/chouhyou/tohoku_center/CenterToshinSalesSlipDetails.vue'),
    meta: {
      title: 'トシン伝票明細',
      desc: '販売管理システムのトシン伝票明細PDF出力です。',
    },
    props:true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// ルーティングトリガー時処理
router.beforeEach(async (to, from, next) => {
  try {
    // console.log('route beforeEach');
    if (to.name !== "Home" && to.path === from.path) {
      // console.log('route beforeEach return');
      return;
    }

    /**
     *  販売管理DB稼働時間チェック
     ***  22:00～7:00の間に東北システムにアクセスがあった場合
     ***  「現在の時間（22:00～翌7:00）販売管理DBへのアクセスはできません。ご注意ください」
     ***  該当時間帯に一度メッセージを出力したら翌日の該当時間帯まではメッセージを出さない
    */
    // 販売管理DB稼働時間外の場合はtrue
    if(isOutOfService()) {
      if(noAlertCache()) {
        alert('現在の時間（22:00～翌7:00）販売管理DBへのアクセスはできません。ご注意ください');
        setAlertCache();
      }
    }

    // ローディングエフェクトON
    store.commit("setLoading", true);

    // ログイン済ユーザー情報取得
    // 未ログインの場合errorを投げるのでここでAPIコール
    const credentials = await Auth.currentAuthenticatedUser();

    /** 
     * ログインユーザーキャッシュチェック
    */ 
    const cachedUser = Cache.getItem('user');
    //console.log({cachedUser});
    // キャッシュが切れていたらサインアウト
    if (cachedUser) {
      // キャッシュ更新
      let date = new Date();
      // const.jsで定義した保持日数をセット
      date.setDate(date.getDate() + AUTH_EXPIRATION_DAYS);
      // console.log('date.getTime()', date.getTime());
      Cache.setItem('user', cachedUser, { expires: date.getTime() });

      // Vuexチェック担当者マスタデータ取得処理
      if (!store.getters.user) {
        // amplify cacheのデータが残っていればセット
        if(cachedUser) {
          store.commit('setUser', cachedUser);
        } else {
          await setUserData(credentials.username);
        }
      }
    } else {
      // Cognitoサインアウト
      await Auth.signOut();
      // エラーを投げてVuexとCacheを初期化
      // console.log('cache expired');
      throw 'Cache Expired';
    }

    // ヘッダ・フッタ表示をON
    store.commit("setShowNav", true);
    // 認証済みにしてハンバーガーメニュー・ヘッダのプルダウンメニューを表示
    store.commit("setAuth", true);
    let authStr = "";
    switch (to.path) {
      // 発注簿システムへのアクセス権限
      case '/order_search':
      case '/order_input':
      case '/csv_print':
      case '/temp_print':
        authStr = store.getters.user.TANTOSHA_AUTH_KBN.slice(-1);
        break;
      // 見積システムへのアクセス権限
      case '/estimate_search':
      case '/estimate_input':
        authStr = store.getters.user.TANTOSHA_AUTH_KBN.slice(-2).slice(0, 1);
        break;
      // 東北Cシステムへのアクセス権限
      case '/center_order_search':
      case '/center_order_input':
      case '/product_price_update_request':
      case '/center_csv_print':
        authStr = store.getters.user.TANTOSHA_AUTH_KBN.slice(-3).slice(0, 1);
        break;
      default:
        break;
    }
    if (authStr !== "" && authStr !== '1') {
      next({
        path: '/',
        query: {
          redirect: to.fullPath,
          message: true
        }
      })
    } else {
      next()
    }
  } catch {
    // console.log('not logged in');
    // ナビゲーションを非表示
    store.commit("setAuth", false);
    // Vuexクリア
    store.commit('setUser', null);
    // キャッシュクリア
    Cache.clear();
    // 未ログインの場合ログイン画面に遷移
    if (to.path !== '/login') { 
      next({ path: '/login' }); 
    } else { 
      next(); 
    } 
  }
})
// ルーティングエラー発生時処理
router.onError(error => {
  // console.error('Failure Reason: ', error);

  // ChunkLoadErrorが発生した際は強制的にページをリロードする
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  }
})

function isOutOfService() {
  /** 現在時取得 */
  const japanStandardTime = new Date().toLocaleString({timeZone: 'Asia/Tokyo'});
  let currentHour = new Date(japanStandardTime).getHours();

  // console.log('currentHour', currentHour);
  
  // 0~7時台、22~23時台は販売管理が動いていないので警告アラートを出す
  if (currentHour <= 7 || currentHour >= 22) {
    return true;
  } else {
    return false;
  }
}

function noAlertCache() {
  if(Cache.getItem('alertHanbaikanriServiceTime')) return false;
  return true;
}

function setAlertCache() {
  /** 現在取得 */
  const japanStandardTime = new Date().toLocaleString({timeZone: 'Asia/Tokyo'});
  let dateObj = new Date(japanStandardTime);

  /** 翌日0時0分0秒のUNIXタイムスタンプ取得 */ 
  // 翌日の現在時刻
  dateObj.setDate(dateObj.getDate() + 1);
  // 0時0分0秒
  dateObj.setHours(0);
  dateObj.setMinutes(0);
  dateObj.setSeconds(0);
  dateObj.setMilliseconds(0);
  console.log(dateObj.getTime());
  
  /** キャッシュにセット */
  Cache.setItem('alertHanbaikanriServiceTime', true, {expires: dateObj.getTime()});
}

export default router
