import { API, graphqlOperation, Auth, Cache } from 'aws-amplify';
import { createT_LOG } from '@/graphql/mutations';
import { ENV } from '@/assets/js/const';
/**
 * 操作ログの登録
 * @param {*} logLevel 
 * @param {*} param 
 * @param {*} e
 */
 export async function addLog(logLevel, param, componentName, methodName, typeName, e=null) {
  // console.log({logLevel});
  // console.log({param});
  // console.log({componentName});
  // console.log({methodName});
  // console.log({typeName});
  // console.log({e});

  // 開発環境以外ではDEBUG・INFOログを出力しない
  switch (ENV) {
    case 'develop':
      break;
    case 'staging':
    case 'production':
      if (logLevel == 'DEBUG' || logLevel == 'INFO')
      return;
      break;
    default:
      break;
  }

  // エラーデータ
  if( e != null ) {
    let errorInfo = e.message ?? e.stack ?? {};
    if(errorInfo) Object.assign( param, { error_info: errorInfo } );
  }

  // Cookieに保存しているバージョン取得
  try {
    const versionCache = Cache.getItem('version');
    Object.assign( param, { version: versionCache } );
  } catch (error) {
    // 何もしない
  }

  // Cognitoユーザーデータ
  let user = null;
  // ログイン済みフラグ
  let isLoggedIn = true;
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    user = cognitoUser.username;
  } catch (error) {
    // 未ログイン
    isLoggedIn = false;
  }

  let input = {
    LOG_LEVEL: logLevel,
    COMPONENT_NAME: componentName,
    METHOD_NAME: methodName,
    TYPE_NAME: typeName,
    MESSAGE_NAME: JSON.stringify(param),
    USER_NAME: user,
    USER_AGENT_NAME: window.navigator.userAgent.toLowerCase(),
    CREATE_USER_NAME: user,
    UPDATE_USER_NAME: user,
  };

  try {
    if (isLoggedIn) {
      await API.graphql(graphqlOperation( createT_LOG, { createT_LOGInput: input } ));
    } else {
      // 未ログインの場合はIAM認証モードでAPIを実行
      await API.graphql({
        query: createT_LOG,
        variables: { createT_LOGInput: input },
        authMode: 'AWS_IAM'
      });
    }
  } catch(error) {
    // 何もしない。
    // console.log('log output error', error);
  }
}
