/** 環境
 * 開発：develop
 * 検証：staging
 * 本番：production
 */
export const ENV = 'production';

/**
 * 認証情報の有効期限日数（アプリでキャッシュする期間）
 * ここで指定された日数連続でアクセスがないとログアウトされる
*/
export const AUTH_EXPIRATION_DAYS = 7;

/**
 * SESのフィードバック転送先メーリングリストアドレス
 * （SES承認されたメールアドレスをセットすること）
 */
export const MAILINGLIST_ADDRESS = "tohoku_admin@sun-ele.co.jp";

/**
 * SESのAPIVersion
 */
 export const SES_API_VERSION = "2010-12-01";
/**
 * AWSのデフォルトリージョン
 */
 export const AWS_DEFAULT_REGION = "ap-northeast-1";

/**
 * 管理者ユーザーの初期所属部門（東北商品センター）
 */
export const AUTH_USER_DEFAULT_DEPARTMENT = 36;

/**
 * 仙台営業所 部門コード
 */
export const DEPARTMENT_SENDAI = 31;

/**
 * 仙南営業所 部門コード
 */
export const DEPARTMENT_SENNAN = 30;

/**
 * 仙北営業所 部門コード
 */
export const DEPARTMENT_SENBOKU = 32;

/**
 * 入力・検索画面で使用する、返品レコード用返品ステータスのリスト
 */
export const RETURN_PRODUCT_STATUS = [
    { id: 1, name: "良品" },
    { id: 2, name: "不良品" },
];

/**
 * 担当者登録・検索で使用する、ユーザーのリスト
 * comboItem    ：表示されるアイテム名
 * code         ：システムの参照権限
 *                 1桁目：発注簿システム参照
 *                 2桁目：見積システム参照
 *                 3桁目：東北商品センターシステム参照
 * isAuthUser   ：管理者かどうか
 */
export const MANAGER_KIND_LIST = [
    {comboItem: "一般ユーザー", code:"011", isAuthUser: false },
    {comboItem: "管理者ユーザー", code:"111", isAuthUser: true },
];

/**
 * 単位のリスト
 */
export const UNIT_LIST = [
    "個",
    "組",
    "箱",
    "本",
    "セット",
    "台",
    "巻",
    "m",
    "面"
];

/**
 * 担当者登録完了メールのログインURL
 */
export const REGISTER_COMPLETE_LOGIN_URL = 'https://tohokusystem.sunele.net/';

/**
 * 担当者登録完了メールの送信元アドレス
 */
export const REGISTER_COMPLETE_FROM_ADDRESS = 'tohoku_admin@sun-ele.co.jp';

/**
 * テーブルフッターオプション（商品検索）
 */
export const CUSTOM_FOOTER_PROPS_PRODUCT_SEARCH = {'items-per-page-options':[10,25,50,500]};

/**
 * テーブルフッターオプション（検索明細）
 */
export const CUSTOM_FOOTER_PROPS_SEARCH_AREA = {'items-per-page-options':[5,10,15,50,100]};

/**
 * 検索結果取得件数上限
 */
 export const RESULT_ROWS_COUNT_LIMIT = 2000;