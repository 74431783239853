/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getM_SHIIRESAKI = /* GraphQL */ `
  query GetM_SHIIRESAKI($BUMON_CD: Int!, $SHIIRESAKI_CD: Int!) {
    getM_SHIIRESAKI(BUMON_CD: $BUMON_CD, SHIIRESAKI_CD: $SHIIRESAKI_CD) {
      BUMON_CD
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      SHIIRESAKI_KANA_NAME
      CSS_SHIIRESAKI_CD
      PANASONIC_KBN
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      SHIIRESAKI_REBATE_KBN
      CHAKUBI_KBN
      HACCHUSHO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listM_SHIIRESAKI = /* GraphQL */ `
  query ListM_SHIIRESAKI($where_options: String) {
    listM_SHIIRESAKI(where_options: $where_options) {
      BUMON_CD
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      SHIIRESAKI_KANA_NAME
      CSS_SHIIRESAKI_CD
      PANASONIC_KBN
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      SHIIRESAKI_REBATE_KBN
      CHAKUBI_KBN
      HACCHUSHO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getM_SHOKISETTEI = /* GraphQL */ `
  query GetM_SHOKISETTEI($BUMON_CD: Int!) {
    getM_SHOKISETTEI(BUMON_CD: $BUMON_CD) {
      BUMON_CD
      BUMON_NAME
      YUBIN_NO
      JUSHO_NAME
      DENWA_NO
      FAX_NO
      HACCHU_NO
      TOSHIN_KAKERITSU_SU
      SHONINSHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listM_SHOKISETTEI = /* GraphQL */ `
  query ListM_SHOKISETTEI($where_options: String) {
    listM_SHOKISETTEI(where_options: $where_options) {
      BUMON_CD
      BUMON_NAME
      YUBIN_NO
      JUSHO_NAME
      DENWA_NO
      FAX_NO
      HACCHU_NO
      TOSHIN_KAKERITSU_SU
      SHONINSHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getM_TANTOSHA = /* GraphQL */ `
  query GetM_TANTOSHA($BUMON_CD: Int!, $TANTOSHA_CD: Int!) {
    getM_TANTOSHA(BUMON_CD: $BUMON_CD, TANTOSHA_CD: $TANTOSHA_CD) {
      LOGIN_USER_CD
      BUMON_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TANTOSHA_ADDRESS
      TANTOSHA_AUTH_KBN
      SHONINSHA_SIGN_NAME
      SHONINSHA_ADDRESS
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getM_TANTOSHA_EXIST_LOGIN_USER = /* GraphQL */ `
  query GetM_TANTOSHA_EXIST_LOGIN_USER($LOGIN_USER_CD: String!) {
    getM_TANTOSHA_EXIST_LOGIN_USER(LOGIN_USER_CD: $LOGIN_USER_CD) {
      LOGIN_USER_CD
      BUMON_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TANTOSHA_ADDRESS
      TANTOSHA_AUTH_KBN
      SHONINSHA_SIGN_NAME
      SHONINSHA_ADDRESS
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listM_TANTOSHA = /* GraphQL */ `
  query ListM_TANTOSHA($where_options: String) {
    listM_TANTOSHA(where_options: $where_options) {
      LOGIN_USER_CD
      BUMON_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TANTOSHA_ADDRESS
      TANTOSHA_AUTH_KBN
      SHONINSHA_SIGN_NAME
      SHONINSHA_ADDRESS
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getM_TOKUISAKI = /* GraphQL */ `
  query GetM_TOKUISAKI($BUMON_CD: Int!, $CSS_TOKUISAKI_CD: Int!) {
    getM_TOKUISAKI(BUMON_CD: $BUMON_CD, CSS_TOKUISAKI_CD: $CSS_TOKUISAKI_CD) {
      BUMON_CD
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      TOKUI_EMAIL_1
      TOKUI_EMAIL_2
      TOKUI_EMAIL_3
      TOKUI_EMAIL_4
      TOKUI_EMAIL_5
      TOKUI_EMAIL_6
      TOKUI_EMAIL_7
      TOKUI_EMAIL_8
      TOKUI_EMAIL_9
      TOKUI_EMAIL_10
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listM_TOKUISAKI = /* GraphQL */ `
  query ListM_TOKUISAKI($where_options: String) {
    listM_TOKUISAKI(where_options: $where_options) {
      BUMON_CD
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      TOKUI_EMAIL_1
      TOKUI_EMAIL_2
      TOKUI_EMAIL_3
      TOKUI_EMAIL_4
      TOKUI_EMAIL_5
      TOKUI_EMAIL_6
      TOKUI_EMAIL_7
      TOKUI_EMAIL_8
      TOKUI_EMAIL_9
      TOKUI_EMAIL_10
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getM_VERSION = /* GraphQL */ `
  query GetM_VERSION($TYPE_NAME: String!) {
    getM_VERSION(TYPE_NAME: $TYPE_NAME) {
      TYPE_NAME
      VERSION_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listM_VERSION = /* GraphQL */ `
  query ListM_VERSION($where_options: String) {
    listM_VERSION(where_options: $where_options) {
      TYPE_NAME
      VERSION_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getT_CENTER_HACCHU_DATA = /* GraphQL */ `
  query GetT_CENTER_HACCHU_DATA($HACCHU_DATA_ID: Int!) {
    getT_CENTER_HACCHU_DATA(HACCHU_DATA_ID: $HACCHU_DATA_ID) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_CENTER_HACCHU_DATA = /* GraphQL */ `
  query ListT_CENTER_HACCHU_DATA($where_options: String) {
    listT_CENTER_HACCHU_DATA(where_options: $where_options) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getT_CENTER_HACCHU_MEISAI_DATA = /* GraphQL */ `
  query GetT_CENTER_HACCHU_MEISAI_DATA(
    $HACCHU_DATA_ID: Int!
    $MEISAI_NO: Int!
  ) {
    getT_CENTER_HACCHU_MEISAI_DATA(
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SHIIRE_KAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      EIGYOU_NAME
      CHAKUBI_DATE
      SHIIRE_DENPYO_NO
      BIKO
      HENDEN_NO
      SENDAI_NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      NOHINSHO_DATE
      TANI_NAME
      TEKA_GK
      EIGYOU_BUMON_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_INSATSU_DATE
      SENDAI_NOHINSU_SU
      SENNAN_NOHINSU_SU
      SENBOKU_NOHINSU_SU
      SENNAN_NYUKA_FLG
      SENBOKU_NYUKA_FLG
      JAN_CD
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      IDOSAKI_BUMON_CD
      CANCEL_FLG
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_CENTER_HACCHU_MEISAI_DATA = /* GraphQL */ `
  query ListT_CENTER_HACCHU_MEISAI_DATA($where_options: String) {
    listT_CENTER_HACCHU_MEISAI_DATA(where_options: $where_options) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SHIIRE_KAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      EIGYOU_NAME
      CHAKUBI_DATE
      SHIIRE_DENPYO_NO
      BIKO
      HENDEN_NO
      SENDAI_NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      NOHINSHO_DATE
      TANI_NAME
      TEKA_GK
      EIGYOU_BUMON_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_INSATSU_DATE
      SENDAI_NOHINSU_SU
      SENNAN_NOHINSU_SU
      SENBOKU_NOHINSU_SU
      SENNAN_NYUKA_FLG
      SENBOKU_NYUKA_FLG
      JAN_CD
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      IDOSAKI_BUMON_CD
      CANCEL_FLG
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listV_CENTER_HACCHU_DATA_WITH_MEISAI = /* GraphQL */ `
  query ListV_CENTER_HACCHU_DATA_WITH_MEISAI($where_options: String) {
    listV_CENTER_HACCHU_DATA_WITH_MEISAI(where_options: $where_options) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SHIIRE_KAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      EIGYOU_NAME
      CHAKUBI_DATE
      SHIIRE_DENPYO_NO
      BIKO
      HENDEN_NO
      SENDAI_NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      NOHINSHO_DATE
      TANI_NAME
      TEKA_GK
      EIGYOU_BUMON_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_INSATSU_DATE
      SENDAI_NOHINSU_SU
      SENNAN_NOHINSU_SU
      SENBOKU_NOHINSU_SU
      SENNAN_NYUKA_FLG
      SENBOKU_NYUKA_FLG
      JAN_CD
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      IDOSAKI_BUMON_CD
      CANCEL_FLG
      SHIIRESAKI_KANA_NAME
      CSS_SHIIRESAKI_CD
      SHIIRESAKI_REBATE_KBN
      CHAKUBI_KBN
      HACCHUSHO_KBN
    }
  }
`;
export const getT_CENTER_SHOHIN_REQUEST = /* GraphQL */ `
  query GetT_CENTER_SHOHIN_REQUEST($IRAI_NO: Int!) {
    getT_CENTER_SHOHIN_REQUEST(IRAI_NO: $IRAI_NO) {
      IRAI_NO
      BUMON_CD
      SHOHIN_CD
      HINBAN_NO
      IRAI_DATE
      SHOHIN_NAME
      TEKA_GK
      OKYAKUSAMA_OROSHIKAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      NET_GK
      SHIIRESAKI_NAME
      SHORI_STATUS_KBN
      SHONIN_IRAISHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_CENTER_SHOHIN_REQUEST = /* GraphQL */ `
  query ListT_CENTER_SHOHIN_REQUEST($where_options: String) {
    listT_CENTER_SHOHIN_REQUEST(where_options: $where_options) {
      IRAI_NO
      BUMON_CD
      SHOHIN_CD
      HINBAN_NO
      IRAI_DATE
      SHOHIN_NAME
      TEKA_GK
      OKYAKUSAMA_OROSHIKAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      NET_GK
      SHIIRESAKI_NAME
      SHORI_STATUS_KBN
      SHONIN_IRAISHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getT_HACCHU_DATA = /* GraphQL */ `
  query GetT_HACCHU_DATA($HACCHU_DATA_ID: Int!) {
    getT_HACCHU_DATA(HACCHU_DATA_ID: $HACCHU_DATA_ID) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_HACCHU_DATA = /* GraphQL */ `
  query ListT_HACCHU_DATA($where_options: String) {
    listT_HACCHU_DATA(where_options: $where_options) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getT_HACCHU_MEISAI_DATA = /* GraphQL */ `
  query GetT_HACCHU_MEISAI_DATA($HACCHU_DATA_ID: Int!, $MEISAI_NO: Int!) {
    getT_HACCHU_MEISAI_DATA(
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SAISHU_SHIIRE_KAKAKU_GK
      CSS_TOKUISAKI_NAME
      CHAKUBI_DATE
      BIKO
      URIDEN_NO
      HENDEN_NO
      OROSHI_KAKAKU_GENKA_GK
      NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      TANI_NAME
      TEKA_GK
      CSS_TOKUISAKI_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_DATE
      JAN_CD
      NYUKAJI_SHIIRE_KAKAKU_GK
      SHIIRESAKI_DENPYO_NO
      REBATE_FLG
      CSS_URIAGE_TANTOSHA_CD
      URIAGE_DENPYO_DATE
      JIKAI_SEIKYU_FLG
      OKYAKUSAMA_OROSHIKAKAKU_GK
      KEISAN_HOSHIKI_NAME
      KAKERITSU_SU
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      URIAGE_CSV_FLG
      URIAGE_CSV_OUTPUT_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      SHIIRE_MACHI_CHECK_FLG
      CHAKUBI_BIKO
      GENKA_IRAI_FLG
      CANCEL_FLG
      EMAIL_SENT_FLG
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      URIAGE_AKA_FLG
      URIAGE_AKA_DATE
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_HACCHU_MEISAI_DATA = /* GraphQL */ `
  query ListT_HACCHU_MEISAI_DATA($where_options: String) {
    listT_HACCHU_MEISAI_DATA(where_options: $where_options) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SAISHU_SHIIRE_KAKAKU_GK
      CSS_TOKUISAKI_NAME
      CHAKUBI_DATE
      BIKO
      URIDEN_NO
      HENDEN_NO
      OROSHI_KAKAKU_GENKA_GK
      NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      TANI_NAME
      TEKA_GK
      CSS_TOKUISAKI_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_DATE
      JAN_CD
      NYUKAJI_SHIIRE_KAKAKU_GK
      SHIIRESAKI_DENPYO_NO
      REBATE_FLG
      CSS_URIAGE_TANTOSHA_CD
      URIAGE_DENPYO_DATE
      JIKAI_SEIKYU_FLG
      OKYAKUSAMA_OROSHIKAKAKU_GK
      KEISAN_HOSHIKI_NAME
      KAKERITSU_SU
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      URIAGE_CSV_FLG
      URIAGE_CSV_OUTPUT_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      SHIIRE_MACHI_CHECK_FLG
      CHAKUBI_BIKO
      GENKA_IRAI_FLG
      CANCEL_FLG
      EMAIL_SENT_FLG
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      URIAGE_AKA_FLG
      URIAGE_AKA_DATE
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listV_HACCHU_DATA_WITH_MEISAI = /* GraphQL */ `
  query ListV_HACCHU_DATA_WITH_MEISAI($where_options: String) {
    listV_HACCHU_DATA_WITH_MEISAI(where_options: $where_options) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SAISHU_SHIIRE_KAKAKU_GK
      CSS_TOKUISAKI_NAME
      CHAKUBI_DATE
      BIKO
      URIDEN_NO
      HENDEN_NO
      OROSHI_KAKAKU_GENKA_GK
      NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      TANI_NAME
      TEKA_GK
      CSS_TOKUISAKI_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_DATE
      JAN_CD
      NYUKAJI_SHIIRE_KAKAKU_GK
      SHIIRESAKI_DENPYO_NO
      REBATE_FLG
      CSS_URIAGE_TANTOSHA_CD
      URIAGE_DENPYO_DATE
      JIKAI_SEIKYU_FLG
      OKYAKUSAMA_OROSHIKAKAKU_GK
      KEISAN_HOSHIKI_NAME
      KAKERITSU_SU
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      URIAGE_CSV_FLG
      URIAGE_CSV_OUTPUT_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      SHIIRE_MACHI_CHECK_FLG
      CHAKUBI_BIKO
      GENKA_IRAI_FLG
      CANCEL_FLG
      EMAIL_SENT_FLG
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      URIAGE_AKA_FLG
      URIAGE_AKA_DATE
      SHIIRESAKI_KANA_NAME
      CSS_SHIIRESAKI_CD
      SHIIRESAKI_REBATE_KBN
      CHAKUBI_KBN
      HACCHUSHO_KBN
    }
  }
`;
export const getT_INFORMATION = /* GraphQL */ `
  query GetT_INFORMATION($HYOJI_DATE: AWSDate!, $MESSAGE_NAME: String!) {
    getT_INFORMATION(HYOJI_DATE: $HYOJI_DATE, MESSAGE_NAME: $MESSAGE_NAME) {
      HYOJI_DATE
      MESSAGE_NAME
      DELETE_FLG
    }
  }
`;
export const listT_INFORMATION = /* GraphQL */ `
  query ListT_INFORMATION($where_options: String) {
    listT_INFORMATION(where_options: $where_options) {
      HYOJI_DATE
      MESSAGE_NAME
      DELETE_FLG
    }
  }
`;
export const getT_LOG = /* GraphQL */ `
  query GetT_LOG($LOG_NO: Int!, $TIMESTAMP_TIME: AWSDateTime!) {
    getT_LOG(LOG_NO: $LOG_NO, TIMESTAMP_TIME: $TIMESTAMP_TIME) {
      LOG_NO
      TIMESTAMP_TIME
      LOG_LEVEL
      COMPONENT_NAME
      METHOD_NAME
      TYPE_NAME
      MESSAGE_NAME
      USER_NAME
      USER_AGENT_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_LOG = /* GraphQL */ `
  query ListT_LOG($where_options: String) {
    listT_LOG(where_options: $where_options) {
      LOG_NO
      TIMESTAMP_TIME
      LOG_LEVEL
      COMPONENT_NAME
      METHOD_NAME
      TYPE_NAME
      MESSAGE_NAME
      USER_NAME
      USER_AGENT_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getT_MITSUMORI_DATA = /* GraphQL */ `
  query GetT_MITSUMORI_DATA($BUMON_CD: Int!, $MITSUMORI_NO: Int!) {
    getT_MITSUMORI_DATA(BUMON_CD: $BUMON_CD, MITSUMORI_NO: $MITSUMORI_NO) {
      MITSUMOTI_DATA_ID
      BUMON_CD
      MITSUMORI_NO
      MITSUMORI_DATE
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      YUKOKIGEN_SU
      NOKI_SU
      KENMEI_NAME
      SAKUSEI_DATE
      TEKIYO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_MITSUMORI_DATA = /* GraphQL */ `
  query ListT_MITSUMORI_DATA($where_options: String) {
    listT_MITSUMORI_DATA(where_options: $where_options) {
      MITSUMOTI_DATA_ID
      BUMON_CD
      MITSUMORI_NO
      MITSUMORI_DATE
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      YUKOKIGEN_SU
      NOKI_SU
      KENMEI_NAME
      SAKUSEI_DATE
      TEKIYO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getT_MITSUMORI_MEISAI_DATA = /* GraphQL */ `
  query GetT_MITSUMORI_MEISAI_DATA(
    $BUMON_CD: Int!
    $MITSUMORI_NO: Int!
    $SHIIRESAKI_CD: Int!
    $SHOHIN_CD: Int!
    $KATABAN_NO: String!
  ) {
    getT_MITSUMORI_MEISAI_DATA(
      BUMON_CD: $BUMON_CD
      MITSUMORI_NO: $MITSUMORI_NO
      SHIIRESAKI_CD: $SHIIRESAKI_CD
      SHOHIN_CD: $SHOHIN_CD
      KATABAN_NO: $KATABAN_NO
    ) {
      MITSUMOTI_DATA_ID
      MEISAI_NO
      SHIIRESAKI_CD
      SHOHIN_CD
      KATABAN_NO
      SHIIRESAKI_NAME
      SHOHIN_NAME
      SURYO_SU
      GENKA_GK
      TANKA_GK
      KINGAKU_GK
      BIKO
      TOSHIN_NET_GK
      TEKA_GK
      COMMENT
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_MITSUMORI_MEISAI_DATA = /* GraphQL */ `
  query ListT_MITSUMORI_MEISAI_DATA($where_options: String) {
    listT_MITSUMORI_MEISAI_DATA(where_options: $where_options) {
      MITSUMOTI_DATA_ID
      MEISAI_NO
      SHIIRESAKI_CD
      SHOHIN_CD
      KATABAN_NO
      SHIIRESAKI_NAME
      SHOHIN_NAME
      SURYO_SU
      GENKA_GK
      TANKA_GK
      KINGAKU_GK
      BIKO
      TOSHIN_NET_GK
      TEKA_GK
      COMMENT
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listV_MITSUMORI_DATA_WITH_MEISAI = /* GraphQL */ `
  query ListV_MITSUMORI_DATA_WITH_MEISAI($where_options: String) {
    listV_MITSUMORI_DATA_WITH_MEISAI(where_options: $where_options) {
      MITSUMORI_DATA_ID
      BUMON_CD
      MITSUMORI_NO
      MEISAI_NO
      MITSUMORI_DATE
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      YUKOKIGEN_SU
      NOKI_SU
      KENMEI_NAME
      SAKUSEI_DATE
      TEKIYO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      SHIIRESAKI_CD
      CSS_SHIIRESAKI_CD
      SHOHIN_CD
      KATABAN_NO
      SHIIRESAKI_NAME
      SHIIRESAKI_KANA_NAME
      SHOHIN_NAME
      SURYO_SU
      GENKA_GK
      TANKA_GK
      KINGAKU_GK
      BIKO
      TOSHIN_NET_GK
      TEKA_GK
      COMMENT
      PANASONIC_KBN
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      SHIIRESAKI_REBATE_KBN
    }
  }
`;
export const getW_CENTER_SHIIRE_CSV_DATA = /* GraphQL */ `
  query GetW_CENTER_SHIIRE_CSV_DATA(
    $BUMON_CD: Int!
    $TANTOSHA_CD: Int!
    $HACCHU_DATA_ID: Int!
    $MEISAI_NO: Int!
  ) {
    getW_CENTER_SHIIRE_CSV_DATA(
      BUMON_CD: $BUMON_CD
      TANTOSHA_CD: $TANTOSHA_CD
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CHAKUBI_DATE
      JITSU_DATE
      CSS_SHIIRESAKI_NAME
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      IDOSAKI_BUMON_CD
      SHIIRE_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listW_CENTER_SHIIRE_CSV_DATA = /* GraphQL */ `
  query ListW_CENTER_SHIIRE_CSV_DATA($where_options: String) {
    listW_CENTER_SHIIRE_CSV_DATA(where_options: $where_options) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CHAKUBI_DATE
      JITSU_DATE
      CSS_SHIIRESAKI_NAME
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      IDOSAKI_BUMON_CD
      SHIIRE_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getW_HACCHU_SHIIRE_CSV_DATA = /* GraphQL */ `
  query GetW_HACCHU_SHIIRE_CSV_DATA(
    $BUMON_CD: Int!
    $TANTOSHA_CD: Int!
    $HACCHU_DATA_ID: Int!
    $MEISAI_NO: Int!
  ) {
    getW_HACCHU_SHIIRE_CSV_DATA(
      BUMON_CD: $BUMON_CD
      TANTOSHA_CD: $TANTOSHA_CD
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CSS_SHIIRESAKI_NAME
      CHAKUBI_DATE
      JITSU_DATE
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      SHIIRESAKI_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listW_HACCHU_SHIIRE_CSV_DATA = /* GraphQL */ `
  query ListW_HACCHU_SHIIRE_CSV_DATA($where_options: String) {
    listW_HACCHU_SHIIRE_CSV_DATA(where_options: $where_options) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CSS_SHIIRESAKI_NAME
      CHAKUBI_DATE
      JITSU_DATE
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      SHIIRESAKI_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const getT_CHOHYO_COUNTER = /* GraphQL */ `
  query GetT_CHOHYO_COUNTER($BUMON_CD: Int!) {
    getT_CHOHYO_COUNTER(BUMON_CD: $BUMON_CD) {
      BUMON_CD
      HACCHUSHO_NO
      PANA_HACCHUSHO_NO
      MITSUMORISHO_NO
      KARI_DENPYO_NO
      HENPIN_DENPYO_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const listT_CHOHYO_COUNTER = /* GraphQL */ `
  query ListT_CHOHYO_COUNTER($where_options: String) {
    listT_CHOHYO_COUNTER(where_options: $where_options) {
      BUMON_CD
      HACCHUSHO_NO
      PANA_HACCHUSHO_NO
      MITSUMORISHO_NO
      KARI_DENPYO_NO
      HENPIN_DENPYO_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
