import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import ja from 'vuetify/es5/locale/ja'

export default new Vuetify({
    lang: {
      locales: { ja },
      current: 'ja',
    },
    theme: {
      themes: {
        light: {
          // サン電子ロゴカラー
          primary: '#013996',
          secondary: '#013996',
          // Staging
          staging: '#007042',
          // Develop
          develop: '#ffa500',
        },
      },
    },
  })