import Vue from 'vue'
import App from './App.vue'
// PWA
import './registerServiceWorker'
// Vue-Router
import router from './router'
// Vuex
import store from './store'
// Vuetify
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
// Amplify
import aws_exports from './aws-exports'
import Amplify from 'aws-amplify'
Amplify.configure(aws_exports)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created: () => (document.documentElement.setAttribute('lang', 'ja')),
}).$mount('#app')
