import store from '@/store'
import { API, graphqlOperation, Cache } from 'aws-amplify';
import { listM_TANTOSHA } from '@/graphql/queries';
import { executeTransactSql, executeHanbaikanriSql } from "@/graphql/mutations";
import { AUTH_USER_DEFAULT_DEPARTMENT, AUTH_EXPIRATION_DAYS } from '@/assets/js/const';
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import moment from 'moment';
/**
 * Amplify Cacheへユーザー情報（担当者マスタ）をセット
 * @param {*} username 
 */
export async function setUserData(username, departmentCd=null) {
  try {
    console.log(`setUserData`);
    // 検索条件
    let condition = {};
    if (!departmentCd) {
      // 部門コード無の場合はリスト取得
      condition = {
        where_options:
        `AND LOGIN_USER_CD = '${username}'`
      };
    } else {
      // 部門コード有の場合は単一レコード取得
      condition = {
        where_options:
        `AND LOGIN_USER_CD = '${username}' AND BUMON_CD = ${departmentCd}`
      };
    }

    // データ取得
    const result = await API.graphql(
      graphqlOperation(listM_TANTOSHA, condition)
    );
    console.dir(result, {depth: 0});
    // 結果判定
    if (result.data.listM_TANTOSHA) {
      // データを取得できた場合
      // 取得結果を格納
      let resultData = null;
      let results = result.data.listM_TANTOSHA;
      console.log({resultData});
      if (results.length > 1) {
        // 管理者ユーザーの場合、複数部門のユーザーを取得するため
        // 初期部門（東北C）をキャッシュに設定
        console.log("set defaultUser");
        for (const data of results) {
          if (data.BUMON_CD === AUTH_USER_DEFAULT_DEPARTMENT) {
            resultData = data;
            break;
          }
        }
        // 管理者のデフォルト部門にユーザー登録がない場合、エラーとする
        if (!resultData) return false;
      } else {
        // 一般ユーザーもしくは管理者ユーザーの部門変更の場合、
        // 単一レコードを取得するため、そのままキャッシュに設定
        resultData = results[0];
        console.log("set resultData");
      }
      store.commit('setUser', resultData);
      let date = new Date();
      // const.jsで定義した保持日数をセット
      date.setDate(date.getDate() + AUTH_EXPIRATION_DAYS);
      Cache.setItem('user', resultData, { expires: date.getTime() });  
      return true;
    } else {
      // データを取得できなかった場合
      // ログ出力
      // addLog('WARN', {
      //   errorType: MSG.WARN["001"],
      //   message: MSG.WARN["001"],
      //   errorMessage: `${username}のレコードが担当者マスタに存在しませんでした`,
      // })
      return false;
    }
  } catch(error) {
    // 異常系操作ログの登録
    // addLog('ERROR', {
    //   errorType: MSG.ERROR["001"],
    //   message: MSG.ERROR["001"],
    //   errorMessage: error.message,
    // })
    console.log("ERROR : error = " + JSON.stringify(error));
    return false;
  }
}
/**
 * Amplify Cacheへユーザー情報（担当者マスタ）をセット
 * @param {*} username 
 */
// export async function setUserData(username, departmentCd=null) {

//  }
/**
 * シングルクオーテーションのエスケープ処理
 * @param {*} text 
 */
 export async function escapeQuote(text) {
  try {
    return text.replace("'", "''");
  } catch(error) {
    // 異常系操作ログの登録
    addLog('ERROR', {
      errorType: MSG.ERROR["001"],
      message: MSG.ERROR["001"],
      errorMessage: error.message,
    })
    return null;
  }
}

/**
 * 印刷レイアウトを設定します。
 * @param {String} size ページサイズ
 */
 async function setPaperCommon(size) {

  // bodyに帳票用のスタイルを設定
  document.body.className = [
    'chouhyou-body', // index.htmlのstyleのセレクタ
    size // paper.cssのセレクタ
  ].join(' ');

  // @pageの設定
  // （vueファイルで@pageがある外部CSSを読み込んだり、直接@pageを記載すると単一スコープ化されず重複してしまう問題の回避策）
  // margin:paper.cssでコメントアウトした部分
  // size  :ページサイズ
  document.head.insertAdjacentHTML('beforeend', '<style>@page{margin:0;size:' + size + ';}</style>');
}

/** 印刷レイアウトをA4縦に設定します。 */
export async function setPaperA4(){
  setPaperCommon('A4');
}

/** 印刷レイアウトをA4横に設定します。 */
export async function setPaperA4Landscape(){
  setPaperCommon('A4 landscape');
}

/**
 * 改行（\r\n,\n）を含む文字列を行毎の文字列配列に変換して返却
 * @param {[String]} value 対象の文字列
 * @param {[Int]} maxLengthRow 行毎の最大文字数（超過した場合は次の行に移動）
 */
 export function splitMultiRowString(value, maxLengthRow) {
  // nullの場合は空文字として扱う
  if (value == null) {
    value = '';
  }
  // \r\nを\nに変換した上で\n毎に分割
  let aryValue = value.replace(/\r\n/g, '\n').split('\n');
  // 各行を確認し、最大文字列長を超過している行がないか確認するループ
  for (let i = 0; i < aryValue.length; i++) {
    // 参照中の行が最大文字数を超過している場合
    if (aryValue[i].length > maxLengthRow) {
      // 超過部分を次の行に移動
      aryValue.splice(i + 1, 0, aryValue[i].substr(maxLengthRow));
      // 次の行に移動した分は現行から除外
      aryValue[i] = aryValue[i].substr(0, maxLengthRow);
    }
  }
  // console.log(aryValue);
  return aryValue;
}

/**
 * 改行（\r\n,\n）を含む文字列を行毎の文字列配列に変換して返却
 * 文字列は半角1文字、全角2文字として計算する（splitMultiRowStringとの違い）
 * @param {[String]} value 対象の文字列
 * @param {[Int]} maxLengthRow 行毎の最大文字数（超過した場合は次の行に移動）
 */
 export function splitMultiRowStringWithByte(value, maxLengthRow) {
  // nullの場合は空文字として扱う
  if (value == null) {
    value = '';
  }
  let aryValue = [];
  let len = 0;
  let strValue = '';
  if (value !== null) {
    // 半角1文字、全角2文字としてカウント
    for (let i = 0; i < value.length; i++) {
      value[i].match(/[ -~]/) || value[i].match(/[ｦ-ﾟ]/) ? len += 1 : len += 2;
      strValue += value[i];
      if(len >= maxLengthRow) {
        aryValue.push(strValue);
        len = 0;
        strValue = '';
      }
    }
    aryValue.push(strValue);
  }
  // console.log(aryValue);
  return aryValue;
}

/**
 * 日付オブジェクトを「YYYY/MM/DD」の形式で返却
 * @param {*} dateObject momentで認識可能な日付オブジェクト（文字列型、日付型等）
 * @param {String} format フォーマット形式、未指定の場合は「YYYY/MM/DD」となる。
 */
 export function formatDate(dateObject, format = 'YYYY/MM/DD') {
  // null値や空白の場合は空白を返却
  if(dateObject == null ||
    dateObject.toString() == '') {
    return '';
  }

  try {
    return moment(dateObject).format(format);
  } catch(error) {
    // 日付型に変換不可の場合はそのままの値を返却
    return dateObject;
  }
}

/**
 * 文字列をBase64エンコードした形式で返却
 * @param {*} parts エンコードする文字列
 */
export function base64Encode(...parts) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      const offset = reader.result.indexOf(",") + 1;
      resolve(reader.result.slice(offset));
    };
    reader.readAsDataURL(new Blob(parts));
  });
}

/**
 * Base64エンコードされた文字列をデコードした文字列で返却
 * @param {*} text Base64エンコードされた文字列
 */
export async function base64Decode(text) {
  const response = await fetch(`data:text/plain;charset=utf-8;base64,` + text);
  return response.text();
}

/**
 * トランザクションを用いたSQL群を発行する
 */
export async function executeTransactSqls(component, methodName, sqlList) {
  // カウンタテーブルの値を更新
  let condition = { SQLs: sqlList };

  // 実行結果取得
  try {
    // AppSync→AuroraServerless(MySQL)でデータ取得
    let result = await API.graphql(
      graphqlOperation(executeTransactSql, condition)
    );
    // レスポンスデータを取得できた際の処理
    if (result) {
      return result.data.executeTransactSql;
    } else {
      // レスポンスデータを取得できなかった際の処理
      // ログ出力(Lamda関数でのSQL実行でエラー)
      addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, component, methodName, TYPE["0003"],);
      return null;
    }
  } catch (error) {
    console.log("deleteTables : 異常終了 = " + JSON.stringify(error));
    // ログ出力(exception)
    addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, component, methodName, TYPE["0003"], error);
    return null;
  }
}

/**
 * 販売管理システムへのデータベースの照会
 */
 export async function executeHanbaikanriSqls(component, methodName, sqlList){
  let response = {};
  let condition = { SQLs: sqlList };
  try {
    // console.info({ condition });
    // AppSync→販売管理検証DB(Oracle)でデータ取得
    let result = await API.graphql(
      graphqlOperation(executeHanbaikanriSql, condition)
    );
    // レスポンスデータを取得できた際の処理
    if (result) {
      const responseBody = JSON.parse(
        result.data.executeHanbaikanriSql.body
      );
      // SQL実行でエラーが発生した場合の処理
      if (result.data.executeHanbaikanriSql.statusCode > 200) {
        // console.info({ responseBody });
        // レスポンスメッセージ
        const message = responseBody.message;
        console.error({ message });
        // エラー内容
        const errorMessage = responseBody.error;
        console.error({ errorMessage });
        // ログ出力(Lamda関数でのSQL実行でステータスエラー)
        addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, component, methodName, TYPE["0003"],);
      }
      response.statusCode = result.data.executeHanbaikanriSql.statusCode;
      response.body = responseBody;
    } else {
      // レスポンスデータを取得できなかった際の処理
      // ログ出力(Lamda関数でのSQL実行でエラー)
      addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, component, methodName, TYPE["0003"],);
      response.body.error = "No response data";
    }
  } catch (error) {
    console.error(error);
    // ログ出力(exception)
    addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, component, methodName, TYPE["0003"], error);
    response.body.error = "An error occurred on executeHanbaikanriSQL";
  }
  return response;
}