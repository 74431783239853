<template>
  <v-container>
    <!-- トップページ -->
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">最新のお知らせ</h1>
        <v-card class="mx-auto text-left" max-width="500" tile>
          <template>
            <v-list-item v-for="history in histories" :key="history.hyojiDate + history.message">
              <v-list-item-content>
                <v-list-item-title>{{ history.hyojiDate }}</v-list-item-title>
                <v-list-item-subtitle>
                  <p>
                      {{ history.message }}
                  </p>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import {
  listT_INFORMATION,
} from "@/graphql/queries";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "HomeArea",

  data: () => ({
    histories: [],
    // 登録日付
    hyojiDate: "",
    // メッセージ
    message: "",
  }),
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="created"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // ローディング画面の開始
    this.$store.commit("setLoading", true);
    // 初期化処理の実行
    await this.initialize();
    // ローディング画面の解除
    this.$store.commit("setLoading", false);

    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="mounted"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディングエフェクトOFF
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * 初期化処理
     * ・データ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検索条件
      let condition = {
        where_options:
          " AND DELETE_FLG = 0" +
          " ORDER BY HYOJI_DATE DESC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listT_INFORMATION, condition)
        );
        // 結果判定
        if (result.data.listT_INFORMATION) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listT_INFORMATION;
          this.histories = [];
          for (const data of resultData) {
            this.histories.push({
              hyojiDate: data.HYOJI_DATE,
              message: data.MESSAGE_NAME,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listT_INFORMATION", where_option:condition}, this.$route.name, method_name, TYPE["0003"],); 
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listT_INFORMATION", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listT_INFORMATION", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
  },
};
</script>
<style scoped>
.v-list-item__subtitle {
  white-space: pre-wrap;
}
</style>