export default {
  "0001": 'InitOperation',
  "0002": 'ButtonOperation',
  "0003": 'DbOperation',
  "0004": 'SubOperation',
  "0005": 'LifeCycle_computed',
  "0006": 'LifeCycle_created',
  "0007": 'LifeCycle_mounted',
  "0008": 'LifeCycle_beforeRouteLeave',
  "0009": 'LifeCycle_watched',
  "0010": 'LifeCycle_beforeUpdate',
  "0011": 'SendEmailOperation',
};