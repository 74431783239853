import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: null,
    user: null,
    loading: false,
    showNav: true,
  },
  getters: {
    auth: state => {
      return state.auth;
    },
    user: state => {
      return state.user;
    },
    loading: state => {
      return state.loading;
    },
    showNav: state => {
      return state.showNav;
    },
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth;
    },
    setUser(state, user) {
      state.user = user
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setShowNav(state, payload) {
      state.showNav = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
