export default {
  DEBUG: {
    "0001": '共通DEBUG_001です',
  },
  INFO: {
    "1001": 'method-start',
    "1002": 'method-end',
    "1003": 'SQL-start',
    "1004": 'SQL-end_OK',
    "1005": 'SQL-end_0',
    "1006": 'UserGet-start',
    "1007": 'UserGet-end',
    "1008": 'Amplify-start',
    "1009": 'Amplify-end',
    "1010": 'sendEmail-start',
    "1011": 'sendEmail-end_OK',
  },
  WARN: {
    "2001": '担当者マスタにログインIDと合致するユーザーデータが存在しません',
  },
  ERROR: {
    "3001": 'common.jsで予期せぬエラーが発生しました',
    "3002": 'SELECT文の実行でエラーが発生しました',
    "3003": 'INSERT文の実行でエラーが発生しました',
    "3004": 'UPDATE文の実行でエラーが発生しました',
    "3005": 'DELETE文の実行でエラーが発生しました',
    "3006": 'AppSyncのAPIでエラーが発生しました',
    "3007": 'Lamda関数でのSQL実行でexceptionが発生しました',
    "3008": 'Lamda関数でのSQL実行でエラーが発生しました',
    "3009": 'Lamda関数でのSQL実行でステータスエラーが発生しました',
    "3010": 'AppSyncのSQL実行でエラーが発生しました',
    "3011": 'ユーザー情報取得でexceptionが発生しました',
    "3012": 'Amplifyの実行でexceptionが発生しました',
    "3013": 'sendEmailの実行でエラーが発生しました',
    "3014": '帳票出力でエラーが発生しました',
  },
  FATAL: {
    "4001": '共通FATAL_001です',
  },
};