<template>
  <div>
    <Loading />
  <!-- v-app Vuetifyを利用するための必須タグ -->
  <v-app>
    <!-- サイドメニュー -->
    <!-- ●TODO 幅を広げた際に自動で展開されるが、閾値を上げたい（コンテンツが隠れてしまうので） -->
    <v-navigation-drawer app v-model="drawer" clipped left width="300px" v-if="$store.getters.auth && $store.getters.showNav">
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title grey--text text--darken-2">
              メインメニュー
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list nav dense>
          <template v-for="sideMenu in sideMenus">
            <v-list-item
              v-if="!sideMenu.lists"
              :to="sideMenu.link"
              :key="sideMenu.name"
            >
              <v-list-item-icon>
                <v-icon>{{ sideMenu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ sideMenu.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              no-action
              :prepend-icon="sideMenu.icon"
              :key="sideMenu.name"
              v-model="sideMenu.active"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ sideMenu.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="list in sideMenu.lists"
                :key="list.name"
                :to="list.link"
              >
                <v-list-item-title>
                  {{ list.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <!-- ヘッダー -->
    <v-app-bar :color="env == 'production' ? 'primary' : 
      env == 'staging' ? 'staging' : 'develop'"
      dark dense app clipped-left v-if="$store.getters.showNav">
      <!-- ハンバーガーアイコン -->
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        style="cursor: pointer"
         v-if="$store.getters.auth"
      ></v-app-bar-nav-icon>
      <!-- サイトタイトル -->
      <!-- ●TODO hoverしたときに指アイコンにしたい -->
      <!-- ●TODO 各機能名を動的にタイトル設定したい（タブ名部分も） -->
      <v-toolbar-title :style="{cursor: $store.getters.auth ? 'pointer' : 'default'}" @click="onTitleClicked"
        >東北システム</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- ユーザーメニュー -->
        <v-menu offset-y v-if="$store.getters.auth">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{($store.getters.user.BUMON_CD ? $store.getters.user.BUMON_CD : ``)
                  + `：` + ($store.getters.user.TANTOSHA_NAME ? $store.getters.user.TANTOSHA_NAME : ``)
              }}<v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-subheader>ユーザーメニュー</v-subheader>
            <v-list-item
              v-for="userMenu in userMenus"
              :key="userMenu.name"
              :to="userMenu.link"
              @click="
                userMenu.click ? handleFunctionCall(userMenu.click) : undefined
              "
            >
              <v-list-item-icon>
                <v-icon>{{ userMenu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ userMenu.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!-- メインコンテンツ -->
    <v-main>
      <router-view />
    </v-main>
    <!-- フッター -->
    <v-footer :color="env == 'production' ? 'primary' : 
      env == 'staging' ? 'staging' : 'develop'"
      dark app v-if="$store.getters.showNav" padless>
      copyright © 2021 SUN Electronics CO.,LTD All right Reserved.
    </v-footer>
  </v-app>
  </div>
</template>

<script>
import { API, Auth, Cache, graphqlOperation } from 'aws-amplify';
import { AUTH_USER_DEFAULT_DEPARTMENT, MANAGER_KIND_LIST, ENV } from '@/assets/js/const';
import Loading from "@/components/Loading";
import { getM_VERSION } from '@/graphql/queries';
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  components: {
    Loading,
  },
  data() {
    return {
      // サイドメニュー開閉の状態 nullにするとデスクトップだとメニューが開き、モバイルだとメニューが閉じた状態で表示
      drawer: null,

      // ユーザーメニュー
      userMenus: [],

      // ユーザーメニュー 共通項目
      commonUserMenu: {
        name: "ログアウト",
        icon: "mdi-logout-variant",
        click: "logOut",
      },

      // ユーザーメニュー 管理者専用項目
      authUserMenu: {
        name: "ユーザー切替",
        icon: "mdi-account-cog",
        click: "changeDepartment",
      },

      // サイドメニュー
      sideMenus: [],

      // システム共通メニュー項目
      commonMenuList: {
        name: "システム共通",
        icon: "mdi-cog",
        active: false,
        link: "",
        lists: [
          {
            name: "初期設定",
            link: "/settings",
          },
          {
            name: "商品検索",
            link: "/product_search",
          },
          {
            name: "商品登録",
            link: "/product_insert",
          },
          {
            name: "仕入先マスタ保守",
            link: "/supplier_search",
          },
          {
            name: "得意先マスタ保守",
            link: "/client_search",
          },
          {
            name: "担当者マスタ保守",
            link: "/manager_search",
          },
        ],
      },

      // 発注簿システムメニュー項目
      orderAuthMenuList: {
        name: "発注簿システム",
        icon: "mdi-lead-pencil",
        active: false,
        link: "",
        lists: [
          {
            name: "発注入力",
            link: "/order_input",
          },
          {
            name: "発注検索",
            link: "/order_search",
          },
          {
            name: "仮伝印刷",
            link: "/temp_print",
          },
        ],},
      // 見積システムメニュー項目
      estimateAuthMenuList: {
        name: "東北圏見積システム",
        icon: "mdi-alarm-panel-outline",
        active: false,
        link: "",
        lists: [
          {
            name: "見積入力",
            link: "/estimate_input",
          },
          {
            name: "見積検索",
            link: "/estimate_search",
          },
        ],
      },
      // 東北商品センターメニュー項目
      centerAuthMenuList: {
        name: "東北商品センター",
        icon: "mdi-barcode",
        active: false,
        link: "",
        lists: [
          {
            name: "発注入力",
            link: "center_order_input",
          },
          {
            name: "発注検索",
            link: "center_order_search",
          },
          {
            name: "商品原価更新依頼",
            link: "product_price_update_request",
          },
        ],
      },
      // ユーザーのシステム参照および管理者権限のリスト
      managerAuthSectionList: MANAGER_KIND_LIST,

      // 環境情報（ヘッダ・フッタの色変更用）
      env: ENV,
    };
  },
  // 以下ページタイトル・メタタグ設定処理
  mounted() {
    this.setPageTitle(this.$route);

    if (this.$router.history._startLocation !== '/login') {
      /** 
       * ログイン画面以外ならキャッシュチェック
       * ログイン画面ではAmplify Cacheが毎回クリアされて変更のチェックが正しく行えないため
       */
      this.checkCacheClear();
    }
  },
  beforeUpdate() {
    const user = Cache.getItem("user");

    if (!user) {
      return;
    }

    // ユーザーメニュー・サイドメニューの初期化
    this.userMenus = [];
    this.sideMenus = [];

    let userAuth = user.TANTOSHA_AUTH_KBN;
    let userCurrentDepartment = user.BUMON_CD;
    
    // 一般ユーザー、管理者ユーザーで共通のメニューを表示
    // ユーザーメニュー
    this.userMenus.push(this.commonUserMenu);
    // サイドメニュー
    this.sideMenus.push(this.commonMenuList);

    // 管理者ユーザーの場合
    let loginUser = this.managerAuthSectionList.find(
      (items) => items.code == this.$store.getters.user.TANTOSHA_AUTH_KBN
    );
    if (loginUser && loginUser.isAuthUser) {
      // ユーザーメニューに管理者用ユーザーメニューを追加
      this.userMenus.push(this.authUserMenu);
    }

    // ログイン中ユーザーの部門が東北商品センターだった場合
    if (userCurrentDepartment !== AUTH_USER_DEFAULT_DEPARTMENT) {
      // 発注簿システムへのアクセス権限有
      if (userAuth.slice(-1) === '1') {
        this.sideMenus.push(this.orderAuthMenuList);
      }
      // 見積システムへのアクセス権限有
      if (userAuth.slice(-2).slice(0, 1) === '1') {
        this.sideMenus.push(this.estimateAuthMenuList);
      }        
    } else {
      // 東北Cシステムへのアクセス権限有
      if (userAuth.slice(-3).slice(0, 1) === '1') {
        this.sideMenus.push(this.centerAuthMenuList);
      }
    }
  },
  watch: {
    $route(to) {
      this.setPageTitle(to);
    },
  },
  methods: {
    /**
     * 関数名を受け取り、対応する関数を呼び出すヘルパー関数
     */
    handleFunctionCall(functionName) {
      this[functionName]();
    },
    /**
     * ログアウト処理
     */
    logOut() {
      this.signOut();
    },
    /**
     * ログアウト処理の実体
     */
    async signOut() {
      try {
        this.$store.commit("setLoading", true);
        // Cognitoサインアウト
        await Auth.signOut();
        // ナビゲーションを非表示
        this.$store.commit("setAuth", false);
        // Vuexクリア
        this.$store.commit('setUser', null);
        // キャッシュクリア
        Cache.clear();
        // console.log("ログアウトしました。");
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
        this.$store.commit("setLoading", false);
      }
    },
    /**
     * タイトル文字列設定処理
     */
    setPageTitle(to) {
      // タイトルを設定
      if (to.meta.title) {
        document.title = to.meta.title;
      } else {
        document.title = "order_record";
      }
      // // メタタグdescription設定
      // if (to.meta.desc) {
      //   document
      //     .querySelector("meta[name='description']")
      //     .setAttribute("content", to.meta.desc);
      // } else {
      //   document
      //     .querySelector("meta[name='description']")
      //     .setAttribute("content", "mock_order_record");
      // }
    },
    /**
     * タイトル文字列押下処理
     */
    onTitleClicked() {
      // 未認証時はタイトル押下不可
      if (this.$store.getters.auth == true) {
        if (this.$route.name !== "Home") {
          this.$router.push('/')
        }
      } else {
        // 何もしない
      }
    },
    /**
     * ユーザー切替メニュー選択処理
     */
    async changeDepartment() {
      if (this.$route.name !== "ChangeAuthUser") {
        // ユーザー切替画面に遷移
        this.$router.push("/change_auth_user");
      }
    },
    /**
     * アプリのバージョンを確認して差分があった場合リソースを再取得する
     */
    async checkCacheClear() {
      // console.log('checkCacheClear');
      const cacheName = 'version'
      // DBに保存しているバージョン取得
      const versionDb = await this.getAppVersion();
      // console.log({versionDb});
      if (versionDb) {
        // Cookieに保存しているバージョン取得
        const versionCache = Cache.getItem(cacheName);
        // console.log({versionCache});
        // Cookie更新
        Cache.setItem(cacheName, versionDb);
        if (versionDb != versionCache) {
          // ServiceWorkerのregistrationを削除
          const registrations = await window.navigator.serviceWorker.getRegistrations();
          if (registrations) {
            for(let registration of registrations) {
              await registration.unregister();
            }
            if (versionCache) {
              // 新旧バージョンをログ出力
              addLog('INFO', {message: JSON.stringify( {OldVersion: versionCache, NewVersion: versionDb })}, this.$route.name, 'checkCacheClear', TYPE["0003"],);
            }
          }
          // キャッシュクリアリロード
          window.location.reload(true);
        }
        // console.log('cache not cleared');
      }
    },
    /**
     * アプリのバージョン取得処理
     */
    async getAppVersion() {
      let result = [];
      const condition = { TYPE_NAME: 'app'};

      try {
        result = await API.graphql(graphqlOperation( getM_VERSION, condition ));
        // console.log({result});
      } catch (error) {
        console.log({error});
        // Exception発生の場合
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_VERSION", where_option:condition}, this.$route.name, 'getAppVersion', TYPE["0003"], error);
        return null;
      }
      // 対象データが存在しない場合
      if( !result.data.getM_VERSION ) {
        // 異常系操作ログの登録
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_VERSION", where_option:condition}, this.$route.name, 'getAppVersion', TYPE["0003"]);
        return null;
      } else {
        if (result.errors) {
          // データを取得できなかった場合
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_VERSION", where_option:condition}, this.$route.name, 'getAppVersion', TYPE["0003"], JSON.stringify(result.errors));
          return null;
        } else {
          // データ0件時
          return result.data.getM_VERSION.VERSION_NO;
        }
      }
    },
  },
};
</script>
<style>
.v-label {
  font-size: 14px !important;
  font-weight: bold !important;
}
.v-messages {
  font-size: 8px !important;
}
.v-text-field {
  padding-top: 8px !important;
  margin-top: 0 !important;
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-input--selection-controls__input + .v-label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px !important;
  font-weight: normal !important;
}
.v-divider--vertical.v-divider--inset {
    margin-top: 0;
    min-height: 0;
    max-height: calc(100% - 14px);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 2px 5px !important;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.style-1 td {
  background-color: rgb(235, 108, 57) !important;
}
.style-2 td {
  background-color: rgb(255, 255, 0) !important;
}
.style-3 td {
  background-color: rgb(0, 225, 255) !important;
}
</style>