<template>
  <div v-show="$store.getters.loading">
    <div class="loading-style">
      <vue-loading 
        type="spiningDubbles"
        color="#FFF"
        :size="{ width: '100px', height: '100px' }"
        >
      </vue-loading>
    </div>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
  name: 'loading',
  components: {
    VueLoading,
  },

  data: () => ({
    loading: false
  })

}
</script>

<style>
.loading-style {
  /* 最大値 */
  z-index:2147483647;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>