<template>
  <home-area />
</template>

<script>
  import HomeArea from '../components/HomeArea'

  export default {
    name: 'Home',

    components: {
      HomeArea,
    },
  }
</script>
