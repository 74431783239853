/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteM_SHIIRESAKI = /* GraphQL */ `
  mutation DeleteM_SHIIRESAKI($BUMON_CD: Int!, $SHIIRESAKI_CD: Int!) {
    deleteM_SHIIRESAKI(BUMON_CD: $BUMON_CD, SHIIRESAKI_CD: $SHIIRESAKI_CD) {
      BUMON_CD
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      SHIIRESAKI_KANA_NAME
      CSS_SHIIRESAKI_CD
      PANASONIC_KBN
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      SHIIRESAKI_REBATE_KBN
      CHAKUBI_KBN
      HACCHUSHO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createM_SHIIRESAKI = /* GraphQL */ `
  mutation CreateM_SHIIRESAKI(
    $createM_SHIIRESAKIInput: CreateM_SHIIRESAKIInput!
  ) {
    createM_SHIIRESAKI(createM_SHIIRESAKIInput: $createM_SHIIRESAKIInput) {
      BUMON_CD
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      SHIIRESAKI_KANA_NAME
      CSS_SHIIRESAKI_CD
      PANASONIC_KBN
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      SHIIRESAKI_REBATE_KBN
      CHAKUBI_KBN
      HACCHUSHO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateM_SHIIRESAKI = /* GraphQL */ `
  mutation UpdateM_SHIIRESAKI(
    $updateM_SHIIRESAKIInput: UpdateM_SHIIRESAKIInput!
  ) {
    updateM_SHIIRESAKI(updateM_SHIIRESAKIInput: $updateM_SHIIRESAKIInput) {
      BUMON_CD
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      SHIIRESAKI_KANA_NAME
      CSS_SHIIRESAKI_CD
      PANASONIC_KBN
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      SHIIRESAKI_REBATE_KBN
      CHAKUBI_KBN
      HACCHUSHO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteM_SHOKISETTEI = /* GraphQL */ `
  mutation DeleteM_SHOKISETTEI($BUMON_CD: Int!) {
    deleteM_SHOKISETTEI(BUMON_CD: $BUMON_CD) {
      BUMON_CD
      BUMON_NAME
      YUBIN_NO
      JUSHO_NAME
      DENWA_NO
      FAX_NO
      HACCHU_NO
      TOSHIN_KAKERITSU_SU
      SHONINSHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createM_SHOKISETTEI = /* GraphQL */ `
  mutation CreateM_SHOKISETTEI(
    $createM_SHOKISETTEIInput: CreateM_SHOKISETTEIInput!
  ) {
    createM_SHOKISETTEI(createM_SHOKISETTEIInput: $createM_SHOKISETTEIInput) {
      BUMON_CD
      BUMON_NAME
      YUBIN_NO
      JUSHO_NAME
      DENWA_NO
      FAX_NO
      HACCHU_NO
      TOSHIN_KAKERITSU_SU
      SHONINSHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateM_SHOKISETTEI = /* GraphQL */ `
  mutation UpdateM_SHOKISETTEI(
    $updateM_SHOKISETTEIInput: UpdateM_SHOKISETTEIInput!
  ) {
    updateM_SHOKISETTEI(updateM_SHOKISETTEIInput: $updateM_SHOKISETTEIInput) {
      BUMON_CD
      BUMON_NAME
      YUBIN_NO
      JUSHO_NAME
      DENWA_NO
      FAX_NO
      HACCHU_NO
      TOSHIN_KAKERITSU_SU
      SHONINSHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteM_TANTOSHA = /* GraphQL */ `
  mutation DeleteM_TANTOSHA(
    $LOGIN_USER_CD: String!
    $BUMON_CD: Int!
    $TANTOSHA_CD: Int!
  ) {
    deleteM_TANTOSHA(
      LOGIN_USER_CD: $LOGIN_USER_CD
      BUMON_CD: $BUMON_CD
      TANTOSHA_CD: $TANTOSHA_CD
    ) {
      LOGIN_USER_CD
      BUMON_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TANTOSHA_ADDRESS
      TANTOSHA_AUTH_KBN
      SHONINSHA_SIGN_NAME
      SHONINSHA_ADDRESS
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createM_TANTOSHA = /* GraphQL */ `
  mutation CreateM_TANTOSHA($createM_TANTOSHAInput: CreateM_TANTOSHAInput!) {
    createM_TANTOSHA(createM_TANTOSHAInput: $createM_TANTOSHAInput) {
      LOGIN_USER_CD
      BUMON_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TANTOSHA_ADDRESS
      TANTOSHA_AUTH_KBN
      SHONINSHA_SIGN_NAME
      SHONINSHA_ADDRESS
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateM_TANTOSHA = /* GraphQL */ `
  mutation UpdateM_TANTOSHA($updateM_TANTOSHAInput: UpdateM_TANTOSHAInput!) {
    updateM_TANTOSHA(updateM_TANTOSHAInput: $updateM_TANTOSHAInput) {
      LOGIN_USER_CD
      BUMON_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TANTOSHA_ADDRESS
      TANTOSHA_AUTH_KBN
      SHONINSHA_SIGN_NAME
      SHONINSHA_ADDRESS
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteM_TOKUISAKI = /* GraphQL */ `
  mutation DeleteM_TOKUISAKI($BUMON_CD: Int!, $CSS_TOKUISAKI_CD: Int!) {
    deleteM_TOKUISAKI(
      BUMON_CD: $BUMON_CD
      CSS_TOKUISAKI_CD: $CSS_TOKUISAKI_CD
    ) {
      BUMON_CD
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      TOKUI_EMAIL_1
      TOKUI_EMAIL_2
      TOKUI_EMAIL_3
      TOKUI_EMAIL_4
      TOKUI_EMAIL_5
      TOKUI_EMAIL_6
      TOKUI_EMAIL_7
      TOKUI_EMAIL_8
      TOKUI_EMAIL_9
      TOKUI_EMAIL_10
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createM_TOKUISAKI = /* GraphQL */ `
  mutation CreateM_TOKUISAKI($createM_TOKUISAKIInput: CreateM_TOKUISAKIInput!) {
    createM_TOKUISAKI(createM_TOKUISAKIInput: $createM_TOKUISAKIInput) {
      BUMON_CD
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      TOKUI_EMAIL_1
      TOKUI_EMAIL_2
      TOKUI_EMAIL_3
      TOKUI_EMAIL_4
      TOKUI_EMAIL_5
      TOKUI_EMAIL_6
      TOKUI_EMAIL_7
      TOKUI_EMAIL_8
      TOKUI_EMAIL_9
      TOKUI_EMAIL_10
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateM_TOKUISAKI = /* GraphQL */ `
  mutation UpdateM_TOKUISAKI($updateM_TOKUISAKIInput: UpdateM_TOKUISAKIInput!) {
    updateM_TOKUISAKI(updateM_TOKUISAKIInput: $updateM_TOKUISAKIInput) {
      BUMON_CD
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      TOKUI_EMAIL_1
      TOKUI_EMAIL_2
      TOKUI_EMAIL_3
      TOKUI_EMAIL_4
      TOKUI_EMAIL_5
      TOKUI_EMAIL_6
      TOKUI_EMAIL_7
      TOKUI_EMAIL_8
      TOKUI_EMAIL_9
      TOKUI_EMAIL_10
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteM_VERSION = /* GraphQL */ `
  mutation DeleteM_VERSION($TYPE_NAME: String!) {
    deleteM_VERSION(TYPE_NAME: $TYPE_NAME) {
      TYPE_NAME
      VERSION_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createM_VERSION = /* GraphQL */ `
  mutation CreateM_VERSION($createM_VERSIONInput: CreateM_VERSIONInput!) {
    createM_VERSION(createM_VERSIONInput: $createM_VERSIONInput) {
      TYPE_NAME
      VERSION_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateM_VERSION = /* GraphQL */ `
  mutation UpdateM_VERSION($updateM_VERSIONInput: UpdateM_VERSIONInput!) {
    updateM_VERSION(updateM_VERSIONInput: $updateM_VERSIONInput) {
      TYPE_NAME
      VERSION_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_CENTER_HACCHU_DATA = /* GraphQL */ `
  mutation DeleteT_CENTER_HACCHU_DATA($HACCHU_DATA_ID: Int!) {
    deleteT_CENTER_HACCHU_DATA(HACCHU_DATA_ID: $HACCHU_DATA_ID) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_CENTER_HACCHU_DATA = /* GraphQL */ `
  mutation CreateT_CENTER_HACCHU_DATA(
    $createT_CENTER_HACCHU_DATAInput: CreateT_CENTER_HACCHU_DATAInput!
  ) {
    createT_CENTER_HACCHU_DATA(
      createT_CENTER_HACCHU_DATAInput: $createT_CENTER_HACCHU_DATAInput
    ) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_CENTER_HACCHU_DATA = /* GraphQL */ `
  mutation UpdateT_CENTER_HACCHU_DATA(
    $updateT_CENTER_HACCHU_DATAInput: UpdateT_CENTER_HACCHU_DATAInput!
  ) {
    updateT_CENTER_HACCHU_DATA(
      updateT_CENTER_HACCHU_DATAInput: $updateT_CENTER_HACCHU_DATAInput
    ) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_CENTER_HACCHU_MEISAI_DATA = /* GraphQL */ `
  mutation DeleteT_CENTER_HACCHU_MEISAI_DATA(
    $HACCHU_DATA_ID: Int!
    $MEISAI_NO: Int!
  ) {
    deleteT_CENTER_HACCHU_MEISAI_DATA(
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SHIIRE_KAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      EIGYOU_NAME
      CHAKUBI_DATE
      SHIIRE_DENPYO_NO
      BIKO
      HENDEN_NO
      SENDAI_NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      NOHINSHO_DATE
      TANI_NAME
      TEKA_GK
      EIGYOU_BUMON_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_INSATSU_DATE
      SENDAI_NOHINSU_SU
      SENNAN_NOHINSU_SU
      SENBOKU_NOHINSU_SU
      SENNAN_NYUKA_FLG
      SENBOKU_NYUKA_FLG
      JAN_CD
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      IDOSAKI_BUMON_CD
      CANCEL_FLG
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_CENTER_HACCHU_MEISAI_DATA = /* GraphQL */ `
  mutation CreateT_CENTER_HACCHU_MEISAI_DATA(
    $createT_CENTER_HACCHU_MEISAI_DATAInput: CreateT_CENTER_HACCHU_MEISAI_DATAInput!
  ) {
    createT_CENTER_HACCHU_MEISAI_DATA(
      createT_CENTER_HACCHU_MEISAI_DATAInput: $createT_CENTER_HACCHU_MEISAI_DATAInput
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SHIIRE_KAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      EIGYOU_NAME
      CHAKUBI_DATE
      SHIIRE_DENPYO_NO
      BIKO
      HENDEN_NO
      SENDAI_NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      NOHINSHO_DATE
      TANI_NAME
      TEKA_GK
      EIGYOU_BUMON_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_INSATSU_DATE
      SENDAI_NOHINSU_SU
      SENNAN_NOHINSU_SU
      SENBOKU_NOHINSU_SU
      SENNAN_NYUKA_FLG
      SENBOKU_NYUKA_FLG
      JAN_CD
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      IDOSAKI_BUMON_CD
      CANCEL_FLG
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_CENTER_HACCHU_MEISAI_DATA = /* GraphQL */ `
  mutation UpdateT_CENTER_HACCHU_MEISAI_DATA(
    $updateT_CENTER_HACCHU_MEISAI_DATAInput: UpdateT_CENTER_HACCHU_MEISAI_DATAInput!
  ) {
    updateT_CENTER_HACCHU_MEISAI_DATA(
      updateT_CENTER_HACCHU_MEISAI_DATAInput: $updateT_CENTER_HACCHU_MEISAI_DATAInput
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SHIIRE_KAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      EIGYOU_NAME
      CHAKUBI_DATE
      SHIIRE_DENPYO_NO
      BIKO
      HENDEN_NO
      SENDAI_NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      NOHINSHO_DATE
      TANI_NAME
      TEKA_GK
      EIGYOU_BUMON_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_INSATSU_DATE
      SENDAI_NOHINSU_SU
      SENNAN_NOHINSU_SU
      SENBOKU_NOHINSU_SU
      SENNAN_NYUKA_FLG
      SENBOKU_NYUKA_FLG
      JAN_CD
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      IDOSAKI_BUMON_CD
      CANCEL_FLG
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_CENTER_SHOHIN_REQUEST = /* GraphQL */ `
  mutation DeleteT_CENTER_SHOHIN_REQUEST($IRAI_NO: Int!) {
    deleteT_CENTER_SHOHIN_REQUEST(IRAI_NO: $IRAI_NO) {
      IRAI_NO
      BUMON_CD
      SHOHIN_CD
      HINBAN_NO
      IRAI_DATE
      SHOHIN_NAME
      TEKA_GK
      OKYAKUSAMA_OROSHIKAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      NET_GK
      SHIIRESAKI_NAME
      SHORI_STATUS_KBN
      SHONIN_IRAISHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_CENTER_SHOHIN_REQUEST = /* GraphQL */ `
  mutation CreateT_CENTER_SHOHIN_REQUEST(
    $createT_CENTER_SHOHIN_REQUESTInput: CreateT_CENTER_SHOHIN_REQUESTInput!
  ) {
    createT_CENTER_SHOHIN_REQUEST(
      createT_CENTER_SHOHIN_REQUESTInput: $createT_CENTER_SHOHIN_REQUESTInput
    ) {
      IRAI_NO
      BUMON_CD
      SHOHIN_CD
      HINBAN_NO
      IRAI_DATE
      SHOHIN_NAME
      TEKA_GK
      OKYAKUSAMA_OROSHIKAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      NET_GK
      SHIIRESAKI_NAME
      SHORI_STATUS_KBN
      SHONIN_IRAISHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_CENTER_SHOHIN_REQUEST = /* GraphQL */ `
  mutation UpdateT_CENTER_SHOHIN_REQUEST(
    $updateT_CENTER_SHOHIN_REQUESTInput: UpdateT_CENTER_SHOHIN_REQUESTInput!
  ) {
    updateT_CENTER_SHOHIN_REQUEST(
      updateT_CENTER_SHOHIN_REQUESTInput: $updateT_CENTER_SHOHIN_REQUESTInput
    ) {
      IRAI_NO
      BUMON_CD
      SHOHIN_CD
      HINBAN_NO
      IRAI_DATE
      SHOHIN_NAME
      TEKA_GK
      OKYAKUSAMA_OROSHIKAKAKU_GK
      SAISHU_SHIIRE_KAKAKU_GK
      NET_GK
      SHIIRESAKI_NAME
      SHORI_STATUS_KBN
      SHONIN_IRAISHA_EMAIL
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_HACCHU_DATA = /* GraphQL */ `
  mutation DeleteT_HACCHU_DATA($HACCHU_DATA_ID: Int!) {
    deleteT_HACCHU_DATA(HACCHU_DATA_ID: $HACCHU_DATA_ID) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_HACCHU_DATA = /* GraphQL */ `
  mutation CreateT_HACCHU_DATA(
    $createT_HACCHU_DATAInput: CreateT_HACCHU_DATAInput!
  ) {
    createT_HACCHU_DATA(createT_HACCHU_DATAInput: $createT_HACCHU_DATAInput) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_HACCHU_DATA = /* GraphQL */ `
  mutation UpdateT_HACCHU_DATA(
    $updateT_HACCHU_DATAInput: UpdateT_HACCHU_DATAInput!
  ) {
    updateT_HACCHU_DATA(updateT_HACCHU_DATAInput: $updateT_HACCHU_DATAInput) {
      HACCHU_DATA_ID
      BUMON_CD
      PANASONIC_KBN
      CHUMON_NO
      CHUMON_DATE
      SHIIRESAKI_CD
      SHIIRESAKI_NAME
      TESURYO_KBN
      TOSHIN_KEIYU_KBN
      TOSHIN_OKYAKUSAMA_CD
      TOSHIN_JUHACCHU_NO
      TOSHIN_HACCHUSHA_CHUMON_NO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      TEKIYO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_HACCHU_MEISAI_DATA = /* GraphQL */ `
  mutation DeleteT_HACCHU_MEISAI_DATA($HACCHU_DATA_ID: Int!, $MEISAI_NO: Int!) {
    deleteT_HACCHU_MEISAI_DATA(
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SAISHU_SHIIRE_KAKAKU_GK
      CSS_TOKUISAKI_NAME
      CHAKUBI_DATE
      BIKO
      URIDEN_NO
      HENDEN_NO
      OROSHI_KAKAKU_GENKA_GK
      NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      TANI_NAME
      TEKA_GK
      CSS_TOKUISAKI_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_DATE
      JAN_CD
      NYUKAJI_SHIIRE_KAKAKU_GK
      SHIIRESAKI_DENPYO_NO
      REBATE_FLG
      CSS_URIAGE_TANTOSHA_CD
      URIAGE_DENPYO_DATE
      JIKAI_SEIKYU_FLG
      OKYAKUSAMA_OROSHIKAKAKU_GK
      KEISAN_HOSHIKI_NAME
      KAKERITSU_SU
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      URIAGE_CSV_FLG
      URIAGE_CSV_OUTPUT_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      SHIIRE_MACHI_CHECK_FLG
      CHAKUBI_BIKO
      GENKA_IRAI_FLG
      CANCEL_FLG
      EMAIL_SENT_FLG
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      URIAGE_AKA_FLG
      URIAGE_AKA_DATE
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_HACCHU_MEISAI_DATA = /* GraphQL */ `
  mutation CreateT_HACCHU_MEISAI_DATA(
    $createT_HACCHU_MEISAI_DATAInput: CreateT_HACCHU_MEISAI_DATAInput!
  ) {
    createT_HACCHU_MEISAI_DATA(
      createT_HACCHU_MEISAI_DATAInput: $createT_HACCHU_MEISAI_DATAInput
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SAISHU_SHIIRE_KAKAKU_GK
      CSS_TOKUISAKI_NAME
      CHAKUBI_DATE
      BIKO
      URIDEN_NO
      HENDEN_NO
      OROSHI_KAKAKU_GENKA_GK
      NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      TANI_NAME
      TEKA_GK
      CSS_TOKUISAKI_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_DATE
      JAN_CD
      NYUKAJI_SHIIRE_KAKAKU_GK
      SHIIRESAKI_DENPYO_NO
      REBATE_FLG
      CSS_URIAGE_TANTOSHA_CD
      URIAGE_DENPYO_DATE
      JIKAI_SEIKYU_FLG
      OKYAKUSAMA_OROSHIKAKAKU_GK
      KEISAN_HOSHIKI_NAME
      KAKERITSU_SU
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      URIAGE_CSV_FLG
      URIAGE_CSV_OUTPUT_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      SHIIRE_MACHI_CHECK_FLG
      CHAKUBI_BIKO
      GENKA_IRAI_FLG
      CANCEL_FLG
      EMAIL_SENT_FLG
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      URIAGE_AKA_FLG
      URIAGE_AKA_DATE
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_HACCHU_MEISAI_DATA = /* GraphQL */ `
  mutation UpdateT_HACCHU_MEISAI_DATA(
    $updateT_HACCHU_MEISAI_DATAInput: UpdateT_HACCHU_MEISAI_DATAInput!
  ) {
    updateT_HACCHU_MEISAI_DATA(
      updateT_HACCHU_MEISAI_DATAInput: $updateT_HACCHU_MEISAI_DATAInput
    ) {
      HACCHU_DATA_ID
      MEISAI_NO
      SHOHIN_CD
      HINBAN_NO
      SHOHIN_NAME
      SURYO_SU
      NET_GK
      SAISHU_SHIIRE_KAKAKU_GK
      CSS_TOKUISAKI_NAME
      CHAKUBI_DATE
      BIKO
      URIDEN_NO
      HENDEN_NO
      OROSHI_KAKAKU_GENKA_GK
      NYUKA_FLG
      KARIDEN_FLG
      HONDEN_FLG
      TANI_NAME
      TEKA_GK
      CSS_TOKUISAKI_CD
      GENBA_NAME
      KARIDEN_INSATSU_FLG
      KARIDEN_DATE
      JAN_CD
      NYUKAJI_SHIIRE_KAKAKU_GK
      SHIIRESAKI_DENPYO_NO
      REBATE_FLG
      CSS_URIAGE_TANTOSHA_CD
      URIAGE_DENPYO_DATE
      JIKAI_SEIKYU_FLG
      OKYAKUSAMA_OROSHIKAKAKU_GK
      KEISAN_HOSHIKI_NAME
      KAKERITSU_SU
      TOSHIN_DENPYO_DATE
      TOSHIN_DENPYO_NO
      TOSHIN_DENPYO_INSATSU_FLG
      TOSHIN_DENPYO_INSATSU_DATE
      URIAGE_CSV_FLG
      URIAGE_CSV_OUTPUT_DATE
      SHIIRE_CSV_FLG
      SHIIRE_CSV_OUTPUT_DATE
      SHIIRE_MACHI_CHECK_FLG
      CHAKUBI_BIKO
      GENKA_IRAI_FLG
      CANCEL_FLG
      EMAIL_SENT_FLG
      HENPIN_STATUS_KBN
      DENPYO_KBN
      HIKIAGE_FLG
      IRAI_FLG
      HENKYAKU_FLG
      SHIIRE_AKA_FLG
      SHIIRE_AKA_DATE
      URIAGE_AKA_FLG
      URIAGE_AKA_DATE
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_INFORMATION = /* GraphQL */ `
  mutation DeleteT_INFORMATION($HYOJI_DATE: AWSDate!, $MESSAGE_NAME: String!) {
    deleteT_INFORMATION(HYOJI_DATE: $HYOJI_DATE, MESSAGE_NAME: $MESSAGE_NAME) {
      HYOJI_DATE
      MESSAGE_NAME
      DELETE_FLG
    }
  }
`;
export const createT_INFORMATION = /* GraphQL */ `
  mutation CreateT_INFORMATION(
    $createT_INFORMATIONInput: CreateT_INFORMATIONInput!
  ) {
    createT_INFORMATION(createT_INFORMATIONInput: $createT_INFORMATIONInput) {
      HYOJI_DATE
      MESSAGE_NAME
      DELETE_FLG
    }
  }
`;
export const updateT_INFORMATION = /* GraphQL */ `
  mutation UpdateT_INFORMATION(
    $updateT_INFORMATIONInput: UpdateT_INFORMATIONInput!
  ) {
    updateT_INFORMATION(updateT_INFORMATIONInput: $updateT_INFORMATIONInput) {
      HYOJI_DATE
      MESSAGE_NAME
      DELETE_FLG
    }
  }
`;
export const deleteT_LOG = /* GraphQL */ `
  mutation DeleteT_LOG($LOG_NO: Int!, $TIMESTAMP_TIME: AWSDateTime!) {
    deleteT_LOG(LOG_NO: $LOG_NO, TIMESTAMP_TIME: $TIMESTAMP_TIME) {
      LOG_NO
      TIMESTAMP_TIME
      LOG_LEVEL
      COMPONENT_NAME
      METHOD_NAME
      TYPE_NAME
      MESSAGE_NAME
      USER_NAME
      USER_AGENT_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_LOG = /* GraphQL */ `
  mutation CreateT_LOG($createT_LOGInput: CreateT_LOGInput!) {
    createT_LOG(createT_LOGInput: $createT_LOGInput) {
      LOG_NO
      TIMESTAMP_TIME
      LOG_LEVEL
      COMPONENT_NAME
      METHOD_NAME
      TYPE_NAME
      MESSAGE_NAME
      USER_NAME
      USER_AGENT_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_LOG = /* GraphQL */ `
  mutation UpdateT_LOG($updateT_LOGInput: UpdateT_LOGInput!) {
    updateT_LOG(updateT_LOGInput: $updateT_LOGInput) {
      LOG_NO
      TIMESTAMP_TIME
      LOG_LEVEL
      COMPONENT_NAME
      METHOD_NAME
      TYPE_NAME
      MESSAGE_NAME
      USER_NAME
      USER_AGENT_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_MITSUMORI_DATA = /* GraphQL */ `
  mutation DeleteT_MITSUMORI_DATA($BUMON_CD: Int!, $MITSUMORI_NO: Int!) {
    deleteT_MITSUMORI_DATA(BUMON_CD: $BUMON_CD, MITSUMORI_NO: $MITSUMORI_NO) {
      MITSUMOTI_DATA_ID
      BUMON_CD
      MITSUMORI_NO
      MITSUMORI_DATE
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      YUKOKIGEN_SU
      NOKI_SU
      KENMEI_NAME
      SAKUSEI_DATE
      TEKIYO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_MITSUMORI_DATA = /* GraphQL */ `
  mutation CreateT_MITSUMORI_DATA(
    $createT_MITSUMORI_DATAInput: CreateT_MITSUMORI_DATAInput!
  ) {
    createT_MITSUMORI_DATA(
      createT_MITSUMORI_DATAInput: $createT_MITSUMORI_DATAInput
    ) {
      MITSUMOTI_DATA_ID
      BUMON_CD
      MITSUMORI_NO
      MITSUMORI_DATE
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      YUKOKIGEN_SU
      NOKI_SU
      KENMEI_NAME
      SAKUSEI_DATE
      TEKIYO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_MITSUMORI_DATA = /* GraphQL */ `
  mutation UpdateT_MITSUMORI_DATA(
    $updateT_MITSUMORI_DATAInput: UpdateT_MITSUMORI_DATAInput!
  ) {
    updateT_MITSUMORI_DATA(
      updateT_MITSUMORI_DATAInput: $updateT_MITSUMORI_DATAInput
    ) {
      MITSUMOTI_DATA_ID
      BUMON_CD
      MITSUMORI_NO
      MITSUMORI_DATE
      CSS_TOKUISAKI_CD
      CSS_TOKUISAKI_NAME
      YUKOKIGEN_SU
      NOKI_SU
      KENMEI_NAME
      SAKUSEI_DATE
      TEKIYO
      LOGIN_USER_CD
      TANTOSHA_CD
      TANTOSHA_NAME
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_MITSUMORI_MEISAI_DATA = /* GraphQL */ `
  mutation DeleteT_MITSUMORI_MEISAI_DATA(
    $BUMON_CD: Int!
    $MITSUMORI_NO: Int!
    $SHIIRESAKI_CD: Int!
    $SHOHIN_CD: Int!
    $KATABAN_NO: String!
  ) {
    deleteT_MITSUMORI_MEISAI_DATA(
      BUMON_CD: $BUMON_CD
      MITSUMORI_NO: $MITSUMORI_NO
      SHIIRESAKI_CD: $SHIIRESAKI_CD
      SHOHIN_CD: $SHOHIN_CD
      KATABAN_NO: $KATABAN_NO
    ) {
      MITSUMOTI_DATA_ID
      MEISAI_NO
      SHIIRESAKI_CD
      SHOHIN_CD
      KATABAN_NO
      SHIIRESAKI_NAME
      SHOHIN_NAME
      SURYO_SU
      GENKA_GK
      TANKA_GK
      KINGAKU_GK
      BIKO
      TOSHIN_NET_GK
      TEKA_GK
      COMMENT
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_MITSUMORI_MEISAI_DATA = /* GraphQL */ `
  mutation CreateT_MITSUMORI_MEISAI_DATA(
    $createT_MITSUMORI_MEISAI_DATAInput: CreateT_MITSUMORI_MEISAI_DATAInput!
  ) {
    createT_MITSUMORI_MEISAI_DATA(
      createT_MITSUMORI_MEISAI_DATAInput: $createT_MITSUMORI_MEISAI_DATAInput
    ) {
      MITSUMOTI_DATA_ID
      MEISAI_NO
      SHIIRESAKI_CD
      SHOHIN_CD
      KATABAN_NO
      SHIIRESAKI_NAME
      SHOHIN_NAME
      SURYO_SU
      GENKA_GK
      TANKA_GK
      KINGAKU_GK
      BIKO
      TOSHIN_NET_GK
      TEKA_GK
      COMMENT
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_MITSUMORI_MEISAI_DATA = /* GraphQL */ `
  mutation UpdateT_MITSUMORI_MEISAI_DATA(
    $updateT_MITSUMORI_MEISAI_DATAInput: UpdateT_MITSUMORI_MEISAI_DATAInput!
  ) {
    updateT_MITSUMORI_MEISAI_DATA(
      updateT_MITSUMORI_MEISAI_DATAInput: $updateT_MITSUMORI_MEISAI_DATAInput
    ) {
      MITSUMOTI_DATA_ID
      MEISAI_NO
      SHIIRESAKI_CD
      SHOHIN_CD
      KATABAN_NO
      SHIIRESAKI_NAME
      SHOHIN_NAME
      SURYO_SU
      GENKA_GK
      TANKA_GK
      KINGAKU_GK
      BIKO
      TOSHIN_NET_GK
      TEKA_GK
      COMMENT
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteW_CENTER_SHIIRE_CSV_DATA = /* GraphQL */ `
  mutation DeleteW_CENTER_SHIIRE_CSV_DATA(
    $BUMON_CD: Int!
    $TANTOSHA_CD: Int!
    $HACCHU_DATA_ID: Int!
    $MEISAI_NO: Int!
  ) {
    deleteW_CENTER_SHIIRE_CSV_DATA(
      BUMON_CD: $BUMON_CD
      TANTOSHA_CD: $TANTOSHA_CD
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CHAKUBI_DATE
      JITSU_DATE
      CSS_SHIIRESAKI_NAME
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      IDOSAKI_BUMON_CD
      SHIIRE_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createW_CENTER_SHIIRE_CSV_DATA = /* GraphQL */ `
  mutation CreateW_CENTER_SHIIRE_CSV_DATA(
    $createW_CENTER_SHIIRE_CSV_DATAInput: CreateW_CENTER_SHIIRE_CSV_DATAInput!
  ) {
    createW_CENTER_SHIIRE_CSV_DATA(
      createW_CENTER_SHIIRE_CSV_DATAInput: $createW_CENTER_SHIIRE_CSV_DATAInput
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CHAKUBI_DATE
      JITSU_DATE
      CSS_SHIIRESAKI_NAME
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      IDOSAKI_BUMON_CD
      SHIIRE_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateW_CENTER_SHIIRE_CSV_DATA = /* GraphQL */ `
  mutation UpdateW_CENTER_SHIIRE_CSV_DATA(
    $updateW_CENTER_SHIIRE_CSV_DATAInput: UpdateW_CENTER_SHIIRE_CSV_DATAInput!
  ) {
    updateW_CENTER_SHIIRE_CSV_DATA(
      updateW_CENTER_SHIIRE_CSV_DATAInput: $updateW_CENTER_SHIIRE_CSV_DATAInput
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CHAKUBI_DATE
      JITSU_DATE
      CSS_SHIIRESAKI_NAME
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      IDOSAKI_BUMON_CD
      SHIIRE_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteW_HACCHU_SHIIRE_CSV_DATA = /* GraphQL */ `
  mutation DeleteW_HACCHU_SHIIRE_CSV_DATA(
    $BUMON_CD: Int!
    $TANTOSHA_CD: Int!
    $HACCHU_DATA_ID: Int!
    $MEISAI_NO: Int!
  ) {
    deleteW_HACCHU_SHIIRE_CSV_DATA(
      BUMON_CD: $BUMON_CD
      TANTOSHA_CD: $TANTOSHA_CD
      HACCHU_DATA_ID: $HACCHU_DATA_ID
      MEISAI_NO: $MEISAI_NO
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CSS_SHIIRESAKI_NAME
      CHAKUBI_DATE
      JITSU_DATE
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      SHIIRESAKI_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createW_HACCHU_SHIIRE_CSV_DATA = /* GraphQL */ `
  mutation CreateW_HACCHU_SHIIRE_CSV_DATA(
    $createW_HACCHU_SHIIRE_CSV_DATAInput: CreateW_HACCHU_SHIIRE_CSV_DATAInput!
  ) {
    createW_HACCHU_SHIIRE_CSV_DATA(
      createW_HACCHU_SHIIRE_CSV_DATAInput: $createW_HACCHU_SHIIRE_CSV_DATAInput
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CSS_SHIIRESAKI_NAME
      CHAKUBI_DATE
      JITSU_DATE
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      SHIIRESAKI_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateW_HACCHU_SHIIRE_CSV_DATA = /* GraphQL */ `
  mutation UpdateW_HACCHU_SHIIRE_CSV_DATA(
    $updateW_HACCHU_SHIIRE_CSV_DATAInput: UpdateW_HACCHU_SHIIRE_CSV_DATAInput!
  ) {
    updateW_HACCHU_SHIIRE_CSV_DATA(
      updateW_HACCHU_SHIIRE_CSV_DATAInput: $updateW_HACCHU_SHIIRE_CSV_DATAInput
    ) {
      BUMON_CD
      TANTOSHA_CD
      HACCHU_DATA_ID
      MEISAI_NO
      CHUMON_NO
      SHOHIN_CD
      HINBAN_NO
      SHIIRESAKI_CD
      CSS_SHIIRESAKI_NAME
      CHAKUBI_DATE
      JITSU_DATE
      SURYO_SU
      SHIIRE_TANKA_GK
      CSS_SHIIRESAKI_CD
      SHIIRESAKI_DENPYO_NO
      DENPYO_KBN
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const deleteT_CHOHYO_COUNTER = /* GraphQL */ `
  mutation DeleteT_CHOHYO_COUNTER($BUMON_CD: Int!) {
    deleteT_CHOHYO_COUNTER(BUMON_CD: $BUMON_CD) {
      BUMON_CD
      HACCHUSHO_NO
      PANA_HACCHUSHO_NO
      MITSUMORISHO_NO
      KARI_DENPYO_NO
      HENPIN_DENPYO_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const createT_CHOHYO_COUNTER = /* GraphQL */ `
  mutation CreateT_CHOHYO_COUNTER(
    $createT_CHOHYO_COUNTERInput: CreateT_CHOHYO_COUNTERInput!
  ) {
    createT_CHOHYO_COUNTER(
      createT_CHOHYO_COUNTERInput: $createT_CHOHYO_COUNTERInput
    ) {
      BUMON_CD
      HACCHUSHO_NO
      PANA_HACCHUSHO_NO
      MITSUMORISHO_NO
      KARI_DENPYO_NO
      HENPIN_DENPYO_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const updateT_CHOHYO_COUNTER = /* GraphQL */ `
  mutation UpdateT_CHOHYO_COUNTER(
    $updateT_CHOHYO_COUNTERInput: UpdateT_CHOHYO_COUNTERInput!
  ) {
    updateT_CHOHYO_COUNTER(
      updateT_CHOHYO_COUNTERInput: $updateT_CHOHYO_COUNTERInput
    ) {
      BUMON_CD
      HACCHUSHO_NO
      PANA_HACCHUSHO_NO
      MITSUMORISHO_NO
      KARI_DENPYO_NO
      HENPIN_DENPYO_NO
      CREATE_DATE
      CREATE_USER_NAME
      UPDATE_DATE
      UPDATE_USER_NAME
    }
  }
`;
export const executeTransactSql = /* GraphQL */ `
  mutation ExecuteTransactSql($SQLs: [String]!) {
    executeTransactSql(SQLs: $SQLs) {
      statusCode
      body
    }
  }
`;
export const executeHanbaikanriSql = /* GraphQL */ `
  mutation ExecuteHanbaikanriSql($SQLs: [String]!) {
    executeHanbaikanriSql(SQLs: $SQLs) {
      statusCode
      body
    }
  }
`;
export const executeCsvFileCreation = /* GraphQL */ `
  mutation ExecuteCsvFileCreation(
    $CsvKind: String!
    $CsvData: String!
    $DepartmentCd: String!
  ) {
    executeCsvFileCreation(
      CsvKind: $CsvKind
      CsvData: $CsvData
      DepartmentCd: $DepartmentCd
    ) {
      statusCode
      body
    }
  }
`;
export const sendRawEmail = /* GraphQL */ `
  mutation SendRawEmail(
    $CC: String!
    $FROM: String!
    $SUBJECT: String!
    $TEXT: String!
    $TO: String!
    $PATH: String!
  ) {
    sendRawEmail(
      CC: $CC
      FROM: $FROM
      SUBJECT: $SUBJECT
      TEXT: $TEXT
      TO: $TO
      PATH: $PATH
    ) {
      statusCode
      body
    }
  }
`;
export const executeExcelFileCreation = /* GraphQL */ `
  mutation ExecuteExcelFileCreation($ExcelKind: String!, $ExcelData: String!) {
    executeExcelFileCreation(ExcelKind: $ExcelKind, ExcelData: $ExcelData) {
      statusCode
      body
    }
  }
`;
